<template>
  <div class="mt-4">
    <div class="container">
      <div class="row mt-4 mb-3 d-flex justify-content-between">
        <div class="col-lg-4">
          <h2 class="text-xl mb-0">Mis movimientos</h2>
        </div>
        <b-button
          v-b-modal.newMovement
          type="button"
          class="btn btn-light mr-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </svg>
        </b-button>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row" v-if="error === 'SAVING_PLAN_NOT_FOUND'">
        <div class="col-12">
          <b-alert show variant="danger"
            >Aún no tiene un plan de ahorros para ver sus movimientos.</b-alert
          >
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <b-table
            :items="movimientosItems"
            :fields="movimientosFields"
            striped
            outlined
            responsive
            show-empty
          >
            <template #empty>
              <p class="text-center mb-0">
                No existen movimientos registrados.
              </p>
            </template>
            <template #cell(fecha)="data">
              {{ $moment(data.item.fecha).format("DD/MM/YYYY") }}
            </template>
            <template #cell(tipo)="data">
              {{ setTextTipoMovimiento(data.item.tipo) }}
            </template>
            <template #cell(numero_participaciones)="data">
              {{ data.item.numero_participaciones }}
            </template>
            <template #cell(precio)="data">
              {{ Number(data.item.precio).toFixed(2) }}
            </template>
            <template #cell(costo_operaciones)="data">
              {{ Number(data.item.costo_operaciones).toFixed(2) }}
            </template>
            <template #cell(importe)="data">
              {{ Number(data.item.importe).toFixed(2) }}
            </template>
            <template #cell(saldo)="data">
              {{ Number(data.item.saldo).toFixed(2) }}
            </template>
            <template #cell(acciones)="data">
              <b-button variant="light" @click="deleteMovement(data.item)"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  /></svg
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
      <div class="col-12" v-if="paginationMovimientos.current_page">
        <b-pagination
          v-model="paginationMovimientos.current_page"
          :total-rows="paginationMovimientos.total_rows"
          :per-page="paginationMovimientos.per_page"
          @change="changePage"
        ></b-pagination>
      </div>
    </div>
    <b-modal
      ref="newMovement"
      id="newMovement"
      centered
      title="Nuevo Movimiento"
      hide-footer
    >
      <b-form @submit="newMovement">
        <b-form-group label="Fecha" label-for="input-fecha">
          <b-form-input
            id="input-fecha"
            v-model="newForm.fecha"
            type="date"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Tipo de movimiento:"
          label-for="input-2"
        >
          <b-form-select
            v-model="newForm.tipo"
            :options="movementTypes"
            @change="selectMovement"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Nº de particpaciones" label-for="input-fecha">
          <b-form-input
            id="input-fecha"
            v-model="newForm.numero_participaciones"
            type="number"
            required
            :disabled="!newForm.onlyImporte"
          ></b-form-input>
        </b-form-group>
        <div class="d-flex">
          <b-form-group label="Precio" label-for="input-fecha" class="mr-1">
            <b-form-input
              id="input-fecha"
              v-model="newForm.precio"
              type="number"
              required
              :disabled="!newForm.onlyImporte"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Coste Operación" label-for="input-fecha">
            <b-form-input
              id="input-fecha"
              v-model="newForm.costo_operaciones"
              type="number"
              required
              :disabled="!newForm.onlyImporte"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Importe" label-for="input-fecha" class="ml-1">
            <b-form-input
              id="input-fecha"
              v-model="newForm.importe"
              type="number"
              :disabled="newForm.onlyImporte"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from "../axios";

export default {
  data() {
    return {
      movimientosFields: [
        {
          label: "Fecha",
          key: "fecha",
          thClass: "text-uppercase text-sm",
        },
        {
          label: "Detalle Aportación",
          key: "tipo",
          thClass: "text-uppercase text-sm",
          tdClass: "text-left",
        },
        {
          label: "N° participaciones",
          key: "numero_participaciones",
          thClass: "text-uppercase text-sm",
          tdClass: "text-right",
        },
        {
          label: "Precio",
          key: "precio",
          thClass: "text-uppercase text-sm text-right",
          tdClass: "text-right",
        },
        {
          label: "Coste Operación",
          key: "costo_operaciones",
          thClass: "text-uppercase text-sm text-right",
          tdClass: "text-right",
        },
        {
          label: "Importe",
          key: "importe",
          thClass: "text-uppercase text-sm text-right",
          tdClass: "text-right",
        },
        {
          label: "Saldo",
          key: "saldo",
          thClass: "text-uppercase text-sm text-right",
          tdClass: "text-right",
        },
        {
          label: "",
          key: "acciones",
          thClass: "text-uppercase text-sm text-right",
          tdClass: "text-right",
        },
      ],
      tipoMovimientos: [
        {
          text: "Ingreso en cuenta",
          value: "ahorro_mensual",
          descripcion:
            "Es el ingreso de efectivo en la cuenta para tener disponibilidad.",
          class: "bg-gray-100 border-gray-500",
        },
        {
          text: "Aportación periódica",
          value: "aportacion_periodica",
          descripcion:
            "Aportación al producto de ahorro que debe de efectuar de manera periódica.",
          class: "bg-blue-100 border-blue-500",
        },
        {
          text: "Aportación Extraordinaria - Barrera 10%",
          value: "aportacion_extraordinaria_10",
          descripcion:
            "Aportación al producto de ahorro que se efectuará cuando experimenta una bajada del 10% desde el último máximo histórico.",
          class: "bg-green-100 border-green-500",
        },
        {
          text: "Aportación Extraordinaria - Barrera 18,50%",
          value: "aportacion_extraordinaria_18",
          descripcion:
            "Aportación al producto de ahorro que se efectuará cuando experimenta una bajada del 18,50% desde el último máximo histórico.",
          class: "bg-indigo-100 border-indigo-500",
        },
        {
          text: "Aportación Extraordinaria - Barrera 34,50%",
          value: "aportacion_extraordinaria_34",
          descripcion:
            "Aportación al producto de ahorro que se efectuará cuando experimenta una bajada del 34,50% desde el último máximo histórico.",
          class: "bg-yellow-100 border-yellow-500",
        },
        {
          text: "Aportación Extraordinaria - Barrera 50%",
          value: "aportacion_extraordinaria_50",
          descripcion:
            "Aportación al producto de ahorro que se efectuará cuando experimenta una bajada del 50% desde el último máximo histórico.",
          class: "bg-red-100 border-red-500",
        },
        {
          text: "Comisiones",
          value: "comision_mantenimiento",
          descripcion:
            "Comisión que nos cobra el bróker relacionada con el producto de ahorro.",
          class: "bg-purple-100 border-purple-500",
        },
        {
          text: "Venta de participaciones",
          value: "recuperacion_ahorro",
          descripcion:
            "Cuando decidimos vender posiciones para ingresar el dinero en la cuenta.",
          class: "bg-pink-100 border-pink-500",
        },
        {
          text: "Retirada de efectivo",
          value: "retirada_dinero",
          descripcion: "Retirada de efectivo de la cuenta.",
          class: "bg-gray-200 border-gray-800",
        },
        {
          text: "Dividendos",
          value: "dividendos",
          descripcion: "Dividendos",
          class: "bg-pink-200 border-pink-800",
        },
        {
          text: "",
          value: "acciones",
          descripcion: "Dividendos",
          class: "bg-pink-200 border-pink-800",
        },
      ],
      movimientosItems: [],
      paginationMovimientos: {
        per_age: null,
        current_page: null,
        total_rows: null,
      },
      error: null,
      movementTypes: [
        { value: "ahorro_mensual", text: "Ingreso a cuenta" },
        { value: "aportacion_periodica", text: "Aportación periódica" },
        {
          value: "aportacion_extraordinaria_10",
          text: "Aportación extraordinaria - Barrera del 10%",
        },
        {
          value: "aportacion_extraordinaria_18",
          text: "Aportación extraordinaria - Barrera del 18.50%",
        },
        {
          value: "aportacion_extraordinaria_34",
          text: "Aportación extraordinaria - Barrera del 34.50%",
        },
        {
          value: "aportacion_extraordinaria_50",
          text: "Aportación extraordinaria - Barrera del 50%",
        },
        { value: "comision_mantenimiento", text: "Comisiones" },
        { value: "recuperacion_ahorro", text: "Venta de participaciones" },
        { value: "retirada_dinero", text: "Retirada en efectivo" },
        { value: "dividendos", text: "Dividendos" },
      ],
      newForm: {
        fecha: "",
        tipo: "ahorro_mensual",
        numero_participaciones: null,
        precio: null,
        costo_operaciones: null,
        importe: null,
        onlyImporte: false,
      },
    };
  },
  created() {
    this.fetchMovimientosCliente(this.$route.params.id_cliente);
  },
  methods: {
    fetchMovimientosCliente(id_cliente) {
      axios
        .get(`/movements/${id_cliente}`)
        .then((res) => {
          // console.log(res)
          this.movimientosItems = res.data.data.data;
          this.paginationMovimientos.per_page = res.data.data.per_page;
          this.paginationMovimientos.current_page = res.data.data.current_page;
          this.paginationMovimientos.total_rows = res.data.data.total;
          this.selectMovement();
        })
        .catch((err) => {
          console.log(err.response);
          this.error = err.response.data.error;
        });
    },
    setTextTipoMovimiento(tipoMovimiento) {
      const tipo = this.tipoMovimientos.find(
        (tipo) => tipo.value === tipoMovimiento
      );
      return tipo.text;
    },
    changePage(page) {
      axios
        .get(`/movements/${this.$route.params.id_cliente}`, {
          params: {
            page,
          },
        })
        .then((res) => {
          this.movimientosItems = res.data.data.data;
          this.paginationMovimientos.total_rows = res.data.data.total;
          this.paginationMovimientos.per_page = res.data.data.per_page;
          this.paginationMovimientos.current_page = res.data.data.current_page;
        })
        .catch((err) => {
          this.$bvToast.toast(err.response.data.message, {
            title: `¡Error!`,
            variant: "danger",
            solid: true,
          });
        });
    },
    selectMovement() {
      console.log(this.newForm.tipo);
      this.newForm.numero_participaciones = null;
      this.newForm.precio = null;
      this.newForm.costo_operaciones = null;
      this.newForm.importe = null;
      if (
        this.newForm.tipo != "ahorro_mensual" &&
        this.newForm.tipo != "comision_mantenimiento" &&
        this.newForm.tipo != "retirada_dinero" &&
        this.newForm.tipo != "dividendos" &&
        this.newForm.tipo != ""
      )
        this.newForm.onlyImporte = true;
      else this.newForm.onlyImporte = false;

      console.log(this.newForm.onlyImporte);
    },
    newMovement(event) {
      event.preventDefault();
      const data = this.newForm;
      Object.keys(data).forEach((k) => data[k] == null && delete data[k]);
      delete data["onlyImporte"];

      data.user_id = this.$route.params.id_cliente;
      axios
        .post("/movements/", data)
        .then((res) => {
          this.$refs["newMovement"].hide();
          this.newForm = {
            fecha: "",
            tipo: "ahorro_mensual",
            numero_participaciones: null,
            precio: null,
            costo_operaciones: null,
            importe: null,
            onlyImporte: false,
          };
          console.log(res);
          this.selectMovement();
          this.fetchMovimientosCliente(this.$route.params.id_cliente);
          this.$bvToast.toast(res.data.message, {
            title: `Éxito`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: `¡Error!`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteMovement(movement) {
      axios
        .delete(`/movements/${movement.movement_id}`)
        .then((res) => {
          this.fetchMovimientosCliente(this.$route.params.id_cliente);
          this.$bvToast.toast(res.data.message, {
            title: `Éxito`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: `¡Error!`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style>
</style>