<template>
  <div class="container mt-4 mb-3">
    <div class="row mt-4 mb-3 align-items-baseline">
      <div class="col-lg-4">
        <h1 class="text-2xl mb-0">Usuarios</h1>
      </div>
    </div>
    <hr class="mt-1 mb-4" />
    <div class="row mb-3">
      <div class="col-12 col-lg-12">
        <b-card no-body>
          <b-tabs v-model="tabIndex" @input="tabChanged" pills card>
            <b-tab title="Admnistradores">
              <div class="container">
                <div class="d-flex justify-content-between mt-3 mb-3">
                  <b-button
                    v-b-toggle.collapse-1
                    variant="outline-secondary"
                    class="d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Filtros
                  </b-button>

                  <b-button
                    variant="success"
                    to="/usuarios/crear"
                    class="d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Nuevo usuario</b-button
                  >
                </div>
                <b-collapse id="collapse-1" class="mt-2">
                  <div class="d-flex justify-content-between">
                    <div class="flex-grow-1 px-3">
                      <b-form-group
                        label="Nombre"
                        label-class="font-weight-bold pt-0"
                      >
                        <b-form-input
                          type="search"
                          placeholder=""
                          v-model="queryNombreUsuario"
                          @update="searchUsuario"
                        ></b-form-input>
                        <span
                          v-if="queryNombreUsuario"
                          @click="
                            queryNombreUsuario = '';
                            searchUsuario();
                          "
                          class="m-1 text-primary"
                          role="button"
                          >Limpiar</span
                        >
                      </b-form-group>
                    </div>
                    <div class="px-3">
                      <b-form-group
                        label="Meses sin movimientos"
                        label-class="font-weight-bold pt-0"
                      >
                        <b-form-spinbutton
                          v-model="monthsWithoutMovements"
                          @change="filterByMonths"
                          min="1"
                          max="100"
                        ></b-form-spinbutton>
                        <span
                          v-if="monthsWithoutMovements"
                          @click="
                            monthsWithoutMovements = null;
                            searchUsuario();
                          "
                          class="m-1 text-primary"
                          role="button"
                          >Limpiar</span
                        >
                      </b-form-group>
                    </div>
                  </div>
                </b-collapse>
                <hr class="mt-1 mb-4" />
                <div class="row">
                  <div class="col-lg-12">
                    <b-table
                      :items="usuariosItems"
                      :fields="usuariosFields"
                      striped
                      outlined
                      responsive
                      show-empty
                    >
                      <template #empty>
                        <p class="text-center">No existen usuarios</p>
                      </template>
                      <template #cell(index)="data">
                        {{ data.index + usuariosPagination.from }}
                      </template>
                      <template #cell(acciones)="data">
                        <b-button
                          class="btn-reset"
                          @click="toggleSidebarDetalleUsuario(data.item)"
                          ><svg
                            class="w-4 h-4 fill-current text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            ></path>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            ></path></svg
                        ></b-button>
                        <b-button class="btn-reset"
                          ><svg
                            class="w-4 h-4 fill-current text-blue-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            ></path></svg
                        ></b-button>
                        <b-button class="btn-reset" @click="eliminarUsuario(data.item)"
                          ><svg
                            class="w-4 h-4 fill-current text-red-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            ></path></svg
                        ></b-button>
                      </template>
                    </b-table>
                  </div>                  
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <b-button
                      variant="success"
                      @click="exportUsersExcel"
                      class="d-flex align-items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Exportar Usuarios</b-button
                    >
                    <b-pagination
                    class="mb-0"
                      v-if="currentPagePagination"
                      v-model="currentPagePagination"
                      :total-rows="usuariosPagination.total"
                      :per-page="usuariosPagination.per_page"
                      align="right"
                      @change="changePage"
                      first-number
                      last-number
                    ></b-pagination>
                  </div>
              </div>
            </b-tab>
            <b-tab title="Clientes" lazy>
              <div class="container">
                <div class="d-flex justify-content-between mt-3 mb-3">
                  <b-button
                    v-b-toggle.collapse-1
                    variant="outline-secondary"
                    class="d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Filtros
                  </b-button>

                  <b-button
                    variant="success"
                    to="/usuarios/crear"
                    class="d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    Nuevo usuario</b-button
                  >
                </div>
                <b-collapse id="collapse-1" class="mt-2">
                  <div class="d-flex justify-content-between">
                    <div class="flex-grow-1 px-3">
                      <b-form-group
                        label="Nombre"
                        label-class="font-weight-bold pt-0"
                      >
                        <b-form-input
                          type="search"
                          placeholder=""
                          v-model="queryNombreUsuario"
                          @update="searchUsuario"
                        ></b-form-input>
                        <span
                          v-if="queryNombreUsuario"
                          @click="
                            queryNombreUsuario = '';
                            searchUsuario();
                          "
                          class="m-1 text-primary"
                          role="button"
                          >Limpiar</span
                        >
                      </b-form-group>
                    </div>
                    <div class="px-3">
                      <b-form-group
                        label="Meses sin movimientos"
                        label-class="font-weight-bold pt-0"
                      >
                        <b-form-spinbutton
                          v-model="monthsWithoutMovements"
                          @change="filterByMonths"
                          min="1"
                          max="100"
                        ></b-form-spinbutton>
                        <span
                          v-if="monthsWithoutMovements"
                          @click="
                            monthsWithoutMovements = null;
                            searchUsuario();
                          "
                          class="m-1 text-primary"
                          role="button"
                          >Limpiar</span
                        >
                      </b-form-group>
                    </div>
                  </div>
                </b-collapse>
                <hr class="mt-1 mb-4" />
                <div class="row">
                  <div class="col-lg-12">
                    <b-table
                      :items="usuariosItems"
                      :fields="usuariosFieldsClientes"
                      striped
                      outlined
                      responsive
                      show-empty
                    >
                      <template #empty>
                        <p class="text-center">No existen usuarios</p>
                      </template>
                      <template #cell(index)="data">
                        {{ data.index + usuariosPagination.from }}
                      </template>
                      <template #cell(acciones)="data">
                        <b-button class="btn-reset" :to="`/usuarios/${data.item.id}`">
                          <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="tools" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 fill-current text-gray-400"><path fill="currentColor" d="M502.6 389.5L378.2 265c-15.6-15.6-36.1-23.4-56.6-23.4-15.4 0-30.8 4.4-44.1 13.3L192 169.4V96L64 0 0 64l96 128h73.4l85.5 85.5c-20.6 31.1-17.2 73.3 10.2 100.7l124.5 124.5c6.2 6.2 14.4 9.4 22.6 9.4 8.2 0 16.4-3.1 22.6-9.4l67.9-67.9c12.4-12.6 12.4-32.8-.1-45.3zM160 158.1v1.9h-48L42.3 67 67 42.3l93 69.7v46.1zM412.1 480L287.7 355.5c-9.1-9.1-14.1-21.1-14.1-33.9 0-12.8 5-24.9 14.1-33.9 9.1-9.1 21.1-14.1 33.9-14.1 12.8 0 24.9 5 33.9 14.1L480 412.1 412.1 480zM64 432c0 8.8 7.2 16 16 16s16-7.2 16-16-7.2-16-16-16-16 7.2-16 16zM276.8 66.9C299.5 44.2 329.4 32 360.6 32c6.9 0 13.8.6 20.7 1.8L312 103.2l13.8 83 83.1 13.8 69.3-69.3c6.7 38.2-5.3 76.8-33.1 104.5-8.9 8.9-19.1 16-30 21.5l23.6 23.6c10.4-6.2 20.2-13.6 29-22.5 37.8-37.8 52.7-91.4 39.7-143.3-2.3-9.5-9.7-17-19.1-19.6-9.5-2.6-19.7 0-26.7 7l-63.9 63.9-44.2-7.4-7.4-44.2L410 50.3c6.9-6.9 9.6-17.1 7-26.5-2.6-9.5-10.2-16.8-19.7-19.2C345.6-8.3 292 6.5 254.1 44.3c-12.9 12.9-22.9 27.9-30.1 44v67.8l22.1 22.1c-9.6-40.4 1.6-82.2 30.7-111.3zM107 467.1c-16.6 16.6-45.6 16.6-62.2 0-17.1-17.1-17.1-45.1 0-62.2l146.1-146.1-22.6-22.6L22.2 382.3c-29.6 29.6-29.6 77.8 0 107.5C36.5 504.1 55.6 512 75.9 512c20.3 0 39.4-7.9 53.7-22.3L231.4 388c-6.7-9.2-11.8-19.3-15.5-29.8L107 467.1z" class=""></path></svg>
                        </b-button>
                        <b-button
                          class="btn-reset"
                          @click="toggleSidebarDetalleUsuario(data.item)"
                          ><svg
                            class="w-4 h-4 fill-current text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            ></path>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            ></path></svg
                        ></b-button>
                        <b-button
                          class="btn-reset"
                          @click="toggleSidebarMovements(data.item)"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-4 h-4 text-success"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
                            /></svg
                        ></b-button>
                        <b-button class="btn-reset" title="Editar cliente" @click="openSidebarEditarCliente(data.item)"
                          ><svg
                            class="w-4 h-4 fill-current text-blue-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            ></path></svg
                        ></b-button>
                        <b-button class="btn-reset"
                          ><svg
                            class="w-4 h-4 fill-current text-red-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            ></path></svg
                        ></b-button>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex ">
                    <b-button
                      variant="success"
                      @click="exportUsersExcel"
                      class="d-flex align-items-center mr-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Exportar Usuarios</b-button
                    >
                    <b-button
                      variant="info"
                      @click="exportAllMovementsExcel"
                      class="d-flex align-items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Exportar todos los movimientos</b-button
                    >
                  </div>
                    <b-pagination
                    class="mb-0"
                      v-if="currentPagePagination"
                      v-model="currentPagePagination"
                      :total-rows="usuariosPagination.total"
                      :per-page="usuariosPagination.per_page"
                      align="right"
                      @change="changePage"
                      first-number
                      last-number
                    ></b-pagination>
                  </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <b-sidebar
      v-model="sidebarDetalleUsuario"
      title="Detalle de usuario"
      id="sidebar-detalle-usuario"
      header-class="d-flex justify-content-between flex-row-reverse border-bottom"
      body-class=""
      aria-controls="sidebar-detalle-usuario"
      :aria-expanded="sidebarDetalleUsuario"
      right
      backdrop
      shadow
    >
      <div class="my-2 px-3 d-flex">
        <b-avatar size="lg" rounded="lg"></b-avatar>
        <div class="ml-2">
          <p class="mb-0 text-xl">{{ detalleUsuario.name }}</p>
          <p class="mb-0 text-sm text-gray-500">
            {{
              detalleUsuario.rol === "administrador"
                ? "Admnistrador"
                : "Cliente"
            }}
          </p>
        </div>
      </div>
      <hr class="mt-0 mb-2" />
      <!-- <h2 class="px-3 text-lg text-gray-400 font-weight-bold">Detalles del perfil</h2> -->
      <div class="px-3 mb-3">
        <label for="" class="text-gray-400 font-weight-bold mb-0">Email</label>
        <p class="mb-0">{{ detalleUsuario.email }}</p>
      </div>
      <div class="px-3 mb-3">
        <label for="" class="text-gray-500 mb-0">Fecha de nacimiento:</label>
        <p class="mb-0">
          {{ detalleUsuario.fecha_nacimiento | moment("DD, MMMM [de] YYYY") }}
        </p>
      </div>
      <div class="px-3 mb-3">
        <label for="" class="text-gray-400 font-weight-bold mb-0"
          >Teléfono</label
        >
        <p class="mb-0">{{ detalleUsuario.telefono }}</p>
      </div>
      <div class="px-3 mb-2">
        <label for="" class="text-gray-400 font-weight-bold mb-0">Género</label>
        <p class="mb-0">{{ detalleUsuario.genero }}</p>
      </div>
      <hr class="mt-3 mb-3" />
      <div class="px-3 mb-3">
        <label for="" class="text-gray-400 font-weight-bold mb-0">País</label>
        <p class="mb-0">{{ detalleUsuario.pais }}</p>
      </div>
      <div class="px-3 mb-2">
        <label for="" class="text-gray-400 font-weight-bold mb-0"
          >Provincia</label
        >
        <p class="mb-0">{{ detalleUsuario.provincia }}</p>
      </div>
      <div class="px-3 mb-3">
        <label for="" class="text-gray-400 font-weight-bold mb-0"
          >Localidad</label
        >
        <p class="mb-0">{{ detalleUsuario.localidad }}</p>
      </div>
      <div class="px-3 mb-3">
        <label for="" class="text-gray-400 font-weight-bold mb-0"
          >Dirección</label
        >
        <p class="mb-0">{{ detalleUsuario.direccion }}</p>
      </div>
      <hr class="mt-3 mb-3" />
      <div class="px-3 mb-3">
        <label for="" class="text-gray-400 font-weight-bold mb-0"
          >Categoría profesional</label
        >
        <p class="mb-0">{{ detalleUsuario.categoria_profesional }}</p>
      </div>
      <div class="px-3 mb-3">
        <label for="" class="text-gray-400 font-weight-bold mb-0"
          >Trabajo</label
        >
        <p class="mb-0">{{ detalleUsuario.trabajo }}</p>
      </div>
    </b-sidebar>
    <b-sidebar
      v-model="sidebarEditarCliente"
      title="Editar cliente"
      id="sidebar-editar-cliente"
      header-class="d-flex justify-content-between flex-row-reverse border-bottom"
      body-class="px-3 py-4"
      aria-controls="sidebar-detalle-usuario"
      :aria-expanded="sidebarDetalleUsuario"
      right
      backdrop
      shadow
    >
      <b-form @submit.prevent="editarUsuario">
        <b-form-group label="Nombre:">
          <b-form-input v-model="dataEditarCliente.name" required></b-form-input>
        </b-form-group>
        <b-form-group label="Teléfono:">
          <b-form-input v-model="dataEditarCliente.telefono" required></b-form-input>
        </b-form-group>
        <b-form-group label="Género:">
          <b-form-select v-model="dataEditarCliente.genero" :options="opcionesGenero" required></b-form-select>
        </b-form-group>
        <b-form-group label="Fecha de nacimiento">
          <b-form-datepicker type="date" v-model="dataEditarCliente.fecha_nacimiento" :date-format-options="{ day: '2-digit', month: 'numeric', year: 'numeric' }" required></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Categoría profesional:">
          <b-form-input v-model="dataEditarCliente.categoria_profesional" required></b-form-input>
        </b-form-group>
        <b-form-group label="Trabajo:">
          <b-form-input v-model="dataEditarCliente.trabajo" required></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" block>Editar datos</b-button>
      </b-form>
    </b-sidebar>
    <b-sidebar
      v-model="sidebarMovements"
      title="Movimientos"
      id="sidebar-movimientos"
      header-class="d-flex justify-content-between flex-row-reverse border-bottom"
      body-class=""
      aria-controls="sidebar-movimientos"
      :aria-expanded="sidebarMovements"
      right
      backdrop
      shadow
      width="800px"
    >
      <div class="px-2 pt-2 mb-2">
        <div class="d-flex justify-content-end">
          <b-button
            variant="success"
            @click="exportMovementsExcel"
            class="d-flex align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                clip-rule="evenodd"
              />
            </svg>
            Exportar</b-button
          >
        </div>
        <b-table
          class="mt-2"
          :items="movementsItems"
          :fields="movementsFields"
          striped
          outlined
          responsive
          show-empty
        >
          <template #cell(tipo)="data">
            <b-badge
              v-if="data.item.tipo == 'ahorro_mensual'"
              pill
              variant="primary"
              >Ahorro Mensual</b-badge
            >
            <b-badge
              v-if="data.item.tipo == 'aportacion_periodica'"
              pill
              variant="primary"
              >Aportacion Periodica</b-badge
            >
            <b-badge
              v-if="data.item.tipo == 'comision_mantenimiento'"
              pill
              variant="primary"
              >Comisión Mantenimiento</b-badge
            >
            <b-badge
              v-if="data.item.tipo == 'recuperacion_ahorro'"
              pill
              variant="primary"
              >Recuperacion Ahorro</b-badge
            >
            <b-badge
              v-if="data.item.tipo == 'retirada_dinero'"
              pill
              variant="primary"
              >Retirada Dinero</b-badge
            >
          </template>
        </b-table>
        <div class="col-lg-12">
          <b-pagination
            v-if="movementsCurrentPagePagination"
            v-model="movementsCurrentPagePagination"
            :total-rows="movementsPagination.total"
            :per-page="movementsPagination.per_page"
            align="right"
            @change="movementschangePage"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      usuariosFields: [
        {
          label: "",
          key: "index",
        },
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "E-mail",
          key: "email",
        },
        {
          label: "Rol",
          key: "rol",
        },
        {
          label: "",
          key: "acciones",
          thClass: "text-right acciones-th",
          tdClass: "text-right acciones-td",
        },
      ],
      usuariosFieldsClientes: [
        {
          label: "",
          key: "index",
        },
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "E-mail",
          key: "email",
        },
        {
          label: "Rol",
          key: "rol",
        },
        {
          label: "",
          key: "acciones",
          thClass: "text-right acciones-th--clientes",
          tdClass: "text-right acciones-td--clientes",
        },
      ],
      usuariosPagination: {},
      usuariosItems: [],
      detalleUsuario: {},
      currentPagePagination: null,
      rol: "administrador",
      queryNombreUsuario: null,
      tabIndex: 0,
      sidebarDetalleUsuario: false,
      sidebarMovements: false,
      sidebarEditarCliente: false,
      monthsWithoutMovements: null,
      movementsFields: [
        {
          label: "",
          key: "index",
        },
        {
          label: "Fecha",
          key: "fecha",
        },
        {
          label: "Tipo",
          key: "tipo",
        },
        {
          label: "# Part.",
          key: "numero_participaciones",
        },
        {
          label: "Precio",
          key: "precio",
        },
        {
          label: "Costo Op.",
          key: "costo_operaciones",
        },
        {
          label: "Importe",
          key: "importe",
        },
        {
          label: "Saldo",
          key: "saldo",
        },
      ],
      movementsItems: [],
      movementsPagination: {},
      movementsCurrentPagePagination: null,
      selectedUser: null,
      dataEditarCliente: {},
      opcionesGenero: [
        {
          value: 'mujer',
          text: 'Mujer'
        },
        {
          value: 'hombre',
          text: 'Hombre'
        },
        {
          value: 'otro',
          text: 'Otro'
        }
      ]
    };
  },
  created() {
    this.fetchUsuarios();
  },
  methods: {
    fetchUsuarios(rol = "administrador") {
      axios
        .get("users", {
          params: {
            rol,
          },
        })
        .then((res) => {
          this.usuariosItems = res.data.data.data;
          this.usuariosPagination.total = res.data.data.total;
          this.usuariosPagination.per_page = res.data.data.per_page;
          this.usuariosPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(page) {
      axios.get("users", {
        params: {
          page,
          usuario: this.queryNombreUsuario,
          rol: this.rol,
          meses: this.monthsWithoutMovements,
        },
      }).then(res => {
        this.usuariosItems = res.data.data.data;
        this.usuariosPagination.total = res.data.data.total;
        this.usuariosPagination.per_page = res.data.data.per_page;
        this.usuariosPagination.from = res.data.data.from;
        this.currentPagePagination = res.data.data.current_page;
      }).catch(err => {
        console.log(err)
      });
    },
    movementschangePage(page) {
      axios
        .get("movements", {
          params: {
            page,
            user_id: this.selectedUser.id,
          },
        })
        .then((res) => {
          this.movementsItems = res.data.data.data;
          this.movementsPagination.total = res.data.data.total;
          this.movementsPagination.per_page = res.data.data.per_page;
          this.movementsPagination.from = res.data.data.from;
          this.movementsCurrentPagePagination = res.data.data.current_page;

          this.sidebarMovements = true;
          console.log(res);
        })
        .catch((err) => {
          this.$bvToast.toast(err.response.data.message, {
            title: `¡Error!`,
            variant: "danger",
            solid: true,
          });
        });
    },
    tabChanged(tabIndex) {
      if (tabIndex === 0) {
        this.rol = "administrador";
        this.queryNombreUsuario = "";
        this.monthsWithoutMovements = null;
        this.fetchUsuarios("administrador");
      } else if (tabIndex === 1) {
        this.rol = "cliente";
        this.queryNombreUsuario = "";
        this.monthsWithoutMovements = null;
        this.fetchUsuarios("cliente");
      }
    },
    searchUsuario(nombre) {
      axios
        .get("users", {
          params: {
            nombre,
            rol: this.rol,
            meses: this.monthsWithoutMovements,
          },
        })
        .then((res) => {
          this.usuariosItems = res.data.data.data;
          this.usuariosPagination.total = res.data.data.total;
          this.usuariosPagination.per_page = res.data.data.per_page;
          this.usuariosPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterByMonths(months) {
      axios
        .get("users", {
          params: {
            nombre: this.queryNombreUsuario,
            rol: this.rol,
            meses: months,
          },
        })
        .then((res) => {
          this.usuariosItems = res.data.data.data;
          this.usuariosPagination.total = res.data.data.total;
          this.usuariosPagination.per_page = res.data.data.per_page;
          this.usuariosPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          this.$bvToast.toast(err.response.data.message, {
            title: `¡Error!`,
            variant: "danger",
            solid: true,
          });
        });
    },
    toggleSidebarDetalleUsuario(usuario) {
      this.sidebarDetalleUsuario = true;
      this.detalleUsuario = usuario;
    },
    toggleSidebarMovements(usuario) {
      this.selectedUser = usuario;
      axios
        .get("movements", {
          params: {
            user_id: usuario.id,
          },
        })
        .then((res) => {
          this.movementsItems = res.data.data.data;
          this.movementsPagination.total = res.data.data.total;
          this.movementsPagination.per_page = res.data.data.per_page;
          this.movementsPagination.from = res.data.data.from;
          this.movementsCurrentPagePagination = res.data.data.current_page;

          this.sidebarMovements = true;
          console.log(res);
        })
        .catch((err) => {
          this.$bvToast.toast(err.response.data.message, {
            title: `¡Error!`,
            variant: "danger",
            solid: true,
          });
        });
    },
    exportMovementsExcel() {
      axios
        .get("movements/export/all", {
          params: {
            user_id: this.selectedUser.id,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.download(response, `${this.selectedUser.name}_movements.xlsx`);
        });
    },
    exportAllMovementsExcel() {
      axios
        .get("movements/export/all", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.download(response, `all_movements.xlsx`);
        });
    },
    exportUsersExcel() {
      axios
        .get("users/export/all", {
          params: {
            nombre: this.queryNombreUsuario,
            rol: this.rol,
            meses: this.monthsWithoutMovements,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.download(response, `users.xlsx`);
        });
    },

    download(response, title) {
      console.log();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    openSidebarEditarCliente(cliente) {
      this.sidebarEditarCliente = true
      this.dataEditarCliente = cliente
    },
    editarUsuario() {
      axios.put(`users/${this.dataEditarCliente.id}`, {
        name: this.dataEditarCliente.name,
        fecha_nacimiento: this.dataEditarCliente.fecha_nacimiento,
        telefono: this.dataEditarCliente.telefono,
        genero: this.dataEditarCliente.genero,
        categoria_profesional: this.dataEditarCliente.categoria_profesional,
        trabajo: this.dataEditarCliente.trabajo
      }).then(res => {
        console.log(res)
        this.dataEditarCliente = {}
        this.sidebarEditarCliente = false
        this.$bvToast.toast('Usuario editado con éxito', {
          title: `Acción exitosa`,
          variant: "success",
          solid: true,
        });
      }).catch(err => {
        console.log(err)
      })
    },
    eliminarUsuario(user) {
      this.$bvModal.msgBoxConfirm('¿Está seguro que desea dar de baja al usuario?', {
        title: 'Mensaje de confirmación',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Confirmar',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-primary',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          axios.delete(`users/${user.id}`)
            .then(_ => {
              this.fetchUsuarios()
            }).catch(err => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })

    }
  },
};
</script>

<style scoped>
.td-acciones-clientes .th-acciones-clientes {
  width: 160px !important;
  min-width: 110px;
}

.td-acciones-clientes .btn {
  margin-left: 0.75rem;
}
</style>