import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import moment from "vue-moment"

const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})

import "./assets/app.scss";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);


Vue.config.productionTip = false;

store.dispatch('me', localStorage.getItem('ant_token'))
  .finally(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  })
