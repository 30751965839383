<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3">
        <div class="col-lg-12">
          <h1 class="text-2xl mb-0">Newsletter</h1>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row align-items-baseline mb-3">
        <div class="col-12 col-md-4 col-lg-4">
          <p class="py-2 mb-0 font-weight-bold">
            Total: <span class="font-weight-normal">{{ newsletterPaginacion.total }}</span>
          </p>
        </div>
        <div class="col-12 col-md-5 offset-md-3 col-lg-5 offset-lg-3">
          <b-form-input placeholder="Buscar por email ..."></b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <b-table
            :items="newsletterItems"
            :fields="newsletterFields"
            striped
            outlined
            responsive
            show-empty
          >
            <template #empty>
              <p class="text-center">
                No existen correos registrados en el newsletter
              </p>
            </template>
            <template #cell(index)="data">
              {{ data.index + newsletterPaginacion.from }}
            </template>
            <template #cell(acciones)>
              <!-- <b-button class="btn-reset"
                ><svg
                  class="w-4 h-4 fill-current text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  ></path></svg
              ></b-button> -->
              <b-button class="btn-reset"
                ><svg
                  class="w-4 h-4 fill-current text-blue-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path></svg
              ></b-button>
              <b-button class="btn-reset"
                ><svg
                  class="w-4 h-4 fill-current text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path></svg
              ></b-button>
            </template>
          </b-table>
        </div>
        <div class="col-lg-12">
          <b-pagination
            v-if="currentPagePagination"
            v-model="currentPagePagination"
            :total-rows="newsletterPaginacion.total"
            :per-page="newsletterPaginacion.per_page"
            align="right"
            @change="changePage"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      newsletterFields: [
        {
          label: "",
          key: "index",
        },
        {
          label: "Nombre",
          key: "nombre",
        },
        {
          label: "Email",
          key: "email",
        },
        {
          label: "",
          key: "acciones",
          thClass: "text-right acciones-th",
          tdClass: "text-right acciones-td border-top-0",
        },
      ],
      newsletterItems: [],
      newsletterPaginacion: {},
      currentPagePagination: null
    };
  },
  created() {
    this.fetchEmails();
  },
  methods: {
    fetchEmails() {
      axios
        .get("newsletters", {
          params: {
            page: this.$route.query.page,
          }
        })
        .then((res) => {
          this.newsletterItems = res.data.data.data;
          this.newsletterPaginacion.total = res.data.data.total;
          this.newsletterPaginacion.per_page = res.data.data.per_page;
          this.newsletterPaginacion.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;

          if (this.$route.query.page) this.currentPagePagination = Number(this.$route.query.page)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(page) {
      axios
        .get("newsletters", {
          params: {
            page,
          },
        })
        .then((res) => {
          this.$router.push({ path: "newsletter", query: { page } })
          this.newsletterItems = res.data.data.data;
          this.newsletterPaginacion.total = res.data.data.total;
          this.newsletterPaginacion.per_page = res.data.data.per_page;
          this.newsletterPaginacion.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>