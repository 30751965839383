<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-lg-4">
          <h1 class="text-2xl mb-0">
            {{
              this.$route.params.texto === "Nueva FAQ"
                ? "Nuevo FAQ"
                : "Editar FAQ"
            }}
          </h1>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <!--@submit.prevent="crearFaq"-->
      <b-form>
        <div class="row">
          <div class="col-md-5 col-lg-5">
            <h4 class="text-lg">
              {{
                this.$route.params.texto === "Nueva FAQ"
                  ? "Nuevo FAQ"
                  : "Editar FAQ"
              }}
            </h4>
            <p class="text-sm">
              {{
                this.$route.params.texto === "Nueva FAQ"
                  ? "Redacta una nueva pregunta frecuente y su respectiva respuesta. Estas tienen que ser claras y concisas."
                  : "Edita una pregunta frecuente y su respectiva respuesta. Estas tienen que ser claras y concisas."
              }}
            </p>
          </div>
          <div class="col-md-7 col-lg-7">
            <div class="row">
              <div class="col-lg-12">
                <!--Muestra este si es nueva-->
                <b-form-group
                  label="Pregunta"
                  v-if="this.$route.params.texto === 'Nueva FAQ'"
                >
                  <b-textarea v-model="faq.pregunta" required></b-textarea>
                </b-form-group>
                <!--Muestra este si es editar-->
                <b-form-group
                  label="Pregunta"
                  v-if="this.$route.params.texto !== 'Nueva FAQ'"
                >
                  <b-textarea v-model="faqEdit.pregunta" required></b-textarea>
                </b-form-group>
              </div>
              <div class="col-lg-12">
                <!--Muestra este si es nueva-->
                <b-form-group
                  label="Respuesta"
                  v-if="this.$route.params.texto === 'Nueva FAQ'"
                >
                  <b-textarea v-model="faq.respuesta" required></b-textarea>
                </b-form-group>
                <!--Muestra este si es editar-->
                <b-form-group
                  label="Respuesta"
                  v-if="this.$route.params.texto !== 'Nueva FAQ'"
                >
                  <b-textarea v-model="faqEdit.respuesta" required></b-textarea>
                </b-form-group>
              </div>
              <div
                class="col-lg-12 d-flex justify-content-left align-items-center"
                v-if="this.$route.params.texto === 'Nueva FAQ'"
              >
                <b-button
                  class="d-left align-items-center"
                  variant="secondary"
                  to="/faqs"
                  >Cancelar</b-button
                >
                <b-button
                  class="d-flex align-items-center ml-2"
                  type="submit"
                  variant="success"
                  @click="callEvent($event)"
                  >Crear</b-button
                >
              </div>
              <div
                class="col-lg-12 d-flex justify-content-left align-items-center"
                v-if="this.$route.params.texto !== 'Nueva FAQ'"
              >
                <b-button
                  class="d-flex align-items-center mr-2"
                  type="submit"
                  variant="success"
                  @click="callEvent($event)"
                  >Guardar</b-button
                >
                <b-button
                  class="d-flex align-items-center"
                  variant="secondary"
                  to="/faqs"
                  >Cancelar</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
    <div class="p-top">
      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>Debe ingresar todos los campos</p>
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  data() {
    return {
      faq: {
        pregunta: "",
        respuesta: "",
      },
      faqEdit: {
        pregunta: this.$route.params.pregunta,
        respuesta: this.$route.params.respuesta,
      },
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  methods: {
    crearFaq() {
      axios
        .post("faqs", this.faq)
        .then((res) => {
          this.$router.push("/faqs");
        })
        .catch((err) => {
          console.log(err);
          this.showAlert();
        });
    },
    editarFaq() {
      axios
        .put(`faqs/${this.$route.params.idFaqEdit}`, this.faqEdit)
        .then((res) => {
          console.log(res);
          this.$router.push("/faqs");
        })
        .catch((error) => {
          console.log(error);
          this.showAlert();
        });
    },
    callEvent(data) {
      console.log(data.target.textContent);
      if (data.target.textContent === "Crear") {
        data.preventDefault();
        this.crearFaq();
      } else {
        data.preventDefault();
        this.editarFaq();
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style scoped>
.p-top {
  padding-top: 10vh;
}
</style>
