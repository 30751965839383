<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-lg-4">
          <h1 class="text-2xl mb-0">Editar ETF</h1>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <b-form @submit.prevent="editarEtf">
        <div class="row">
          <div class="col-md-5 col-lg-5">
            <h4 class="text-lg">Información del ETF</h4>
            <p class="text-sm">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum
              sint numquam laboriosam soluta, rerum officiis quod nisi provident
              et a.
            </p>
          </div>
          <div class="col-md-7 col-lg-7">
            <div class="row">
              <div class="col-lg-6">
                <b-form-group label="Identificador del ETF">
                  <b-form-input type="text" v-model="etf.identificador" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group label="ISIN">
                  <b-form-input type="text" v-model="etf.isin" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group label="Gestora">
                  <b-form-input type="text" v-model="etf.gestora" required></b-form-input>
                </b-form-group>
              </div>
              <!-- <div class="col-lg-6">
                <b-form-group label="Imagen de gestora">
                  <b-form-input placeholder="Url de la imagen" v-model="etf.gestora_imagen"></b-form-input>
                </b-form-group>
              </div> -->
              <div class="col-lg-6">
                <b-form-group label="Patrimonio">
                  <b-form-input type="text" v-model="etf.patrimonio" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group label="Mercado">
                  <b-form-input type="text" v-model="etf.mercado" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group label="Bolsa">
                  <b-form-input type="text" v-model="etf.bolsa" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6">
                <b-form-group label="Symbol">
                  <b-form-input type="text" v-model="etf.symbol_google" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-12">
                <b-form-group label="Descripción">
                  <b-textarea v-model="etf.descripcion" required></b-textarea>
                </b-form-group>
              </div>
              <div class="col-lg-12">
                <b-form-group>
                  <b-form-checkbox v-model="etf.gratis_degiro">Gratis de giro</b-form-checkbox>
                </b-form-group>
              </div>
              <div class="col-lg-12">
                <b-button type="submit" variant="success">Guardar ETF</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from "@/axios"
export default {
  data() {
    return {
      etf: {
        identificador: "",
        gestora: "",
        // gestora_imagen: "",
        isin: "",
        patrimonio: "",
        mercado: "",
        bolsa: "",
        symbol_google: "",
        descripcion: "",
        gratis_degiro: false
      }
    }
  },
  created() {
    if (this.$route.params.dataEtf) {
      let etf = this.$route.params.dataEtf
      this.etf.identificador = etf.identificador;
      this.etf.gestora = etf.gestora;
      // this.etf.gestora_imagen = etf.gestora_imagen;
      this.etf.isin = etf.isin;
      this.etf.patrimonio = etf.patrimonio;
      this.etf.mercado = etf.mercado;
      this.etf.bolsa = etf.bolsa;
      this.etf.symbol_google = etf.symbol_google;
      this.etf.descripcion = etf.descripcion;
      this.etf.gratis_degiro = etf.gratis_degiro ? true : false;
    } else {
      this.fetchEtf()
    }
  },
  methods: {
    fetchEtf() {
      axios.get(`etfs/${this.$route.params.idEtf}`)
      .then(res => {
        this.etf.identificador = res.data.data.identificador
        this.etf.gestora = res.data.data.gestora
        // this.etf.gestora_imagen = res.data.data.gestora_imagen
        this.etf.isin = res.data.data.isin
        this.etf.patrimonio = res.data.data.patrimonio
        this.etf.mercado = res.data.data.mercado
        this.etf.bolsa = res.data.data.bolsa
        this.etf.symbol_google = res.data.data.symbol_google
        this.etf.descripcion = res.data.data.descripcion
        this.etf.gratis_degiro = res.data.data.gratis_degiro ? true : false
      }).catch(err => {
        console.log(err)
      })
    },
    editarEtf() {
      axios.put(`etfs/${this.$route.params.idEtf}`, this.etf)
      .then(res => {
        console.log(res)
        this.$router.push("/etfs")
      }).catch(err => {
        console.log(err)
      })
    }
  }
};
</script>

<style>
</style>