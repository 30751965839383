import axios from "@/axios"

const auth = {
  state() {
    return {
      user: null,
      token: null
    }
  },
  getters: {
    loggedIn(state) {
      return Boolean(state.token) && Boolean(state.user) && Boolean(state.user.rol === 'administrador')
    },
    userToken (state) {
      return state.token
    },
    user (state) {
      return state.user
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    me({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.post('me')
        .then(res => {
          commit('SET_TOKEN', token)
          commit('SET_USER', res.data.data)
          localStorage.setItem("ant_token", token)
          localStorage.setItem("ant_user", JSON.stringify(res.data.data))
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    login({ commit }, user) {
      return new Promise ((resolve, reject) => {
        axios.post('login', {
          email: user.email,
          password: user.password
        }).then(res => {
          commit('SET_TOKEN', res.data.access_token)
          commit('SET_USER', res.data.data)
          localStorage.setItem("ant_token", res.data.access_token)
          localStorage.setItem("ant_user", JSON.stringify(res.data.data))
          resolve(res)
        }).catch(err => {
          commit('SET_TOKEN', null)
          commit('SET_USER', null)
          localStorage.removeItem('ant_token')
          localStorage.removeItem('ant_user')
          console.log(err)
          reject(err)
        })
      })
    },
    logout({ commit }) {
      return new Promise ((resolve, reject) => {
        axios.post('logout')
        .then(res => {
          commit('SET_TOKEN', null)
          commit('SET_USER', null)
          localStorage.removeItem('ant_token')
          localStorage.removeItem('ant_user')
          resolve(res)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }
  }
}

export default auth