<template>
  <div class="container mb-4">
    <div class="row mt-4 mb-3">
      <div class="col-lg-4">
        <h1 class="text-2xl mb-0">Nuevo usuario</h1>
      </div>
    </div>
    <hr class="mt-0 mb-4" />
    <b-form @submit.prevent="crearUsuario">
      <div class="row">
        <div class="col-md-5 col-lg-5">
          <h4 class="text-lg">Información básica</h4>
        </div>
        <div class="col-md-7 col-lg-7">
          <div class="row">
            <div class="col-lg-12">
              <b-form-group label="Correo electrónico">
                <b-form-input type="email" v-model="user.email" required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <b-form-group label="Contraseña">
                <b-form-input
                  v-model="user.password"
                  type="password"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group label="Repetir Contraseña">
                <b-form-input
                  v-model="user.password_confirmation"
                  type="password"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <b-form-group label="Teléfono">
                <b-form-input type="tel" v-model="user.telefono" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group label="Fecha Nacimiento">
                <b-form-input
                  v-model="user.fecha_nacimiento"
                  type="date"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-2 mb-3" />
      <div class="row">
        <div class="col-md-5 col-lg-5">
          <h4 class="text-lg">Información del perfil</h4>
        </div>
        <div class="col-md-7 col-lg-7">
          <div class="row">
            <div class="col-lg-12">
              <b-form-group label="Nombres">
                <b-form-input v-model="user.name" required></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <b-form-group label="Género">
                <b-form-select
                  :options="generoOptions"
                  v-model="user.genero"
                  required
                >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group label="Rol">
                <b-form-select
                  :options="rolOptions"
                  v-model="user.rol"
                  required
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-12">
              <b-button
              type="submit"
                variant="success"
                class="d-block w-100 d-md-inline-block w-md-auto"
                >Crear usuario</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from "@/axios"

export default {
  data() {
    return {
      user: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        telefono: null,
        genero: null,
        fecha_nacimiento: null,
        avatar_url: "https://url.com",
        rol: null,
      },
      generoOptions: [
        {
          value: null,
          text: "Eescoger género",
          disabled: true,
        },
        {
          value: "otro",
          text: "Otro",
        },
        {
          value: "mujer",
          text: "Mujer",
        },
        {
          value: "hombre",
          text: "Hombre",
        },
      ],
      rolOptions: [
        {
          value: null,
          text: "Escoger rol",
          disabled: true,
        },
        {
          value: "administrador",
          text: "Administrador",
        },
        {
          value: "cliente",
          text: "Cliente",
        },
      ],
    };
  },
  methods: {
    crearUsuario() {
      axios
        .post("register-admin", this.user)
        .then((res) => {
          console.log(res);
          this.$router.push("/usuarios");
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style scoped>
</style>