<template>
  <div class="mt-4">
    <div class="" v-if="planesDeAhorro.length === 0">
      <p class="text-center">¡No tienes un plan de ahorros creado!</p>
    </div>
    <div class="" v-else>
      <div
        class="container"
        v-for="(planDeAhorro, key) in planesDeAhorro"
        :key="key"
      >
        <div class="row mt-4 mb-3 d-flex justify-content-between">
          <div class="col-lg-4">
            <h2 v-if="!isEditing" class="text-xl mb-0">
              {{ planDeAhorro.plan_ahorros.nombre }}
            </h2>
            <input
              v-else
              type="text"
              class="form-control"
              v-model="editNombre"
            />
          </div>
          <button
            v-if="!isEditing"
            type="button"
            class="btn btn-light mr-3"
            @click="edit(planDeAhorro)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
              />
            </svg>
          </button>
          <button
            v-else
            type="button"
            class="btn btn-light mr-3"
            @click="update(planDeAhorro.plan_ahorros.saving_plan_id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z"
              />
            </svg>
          </button>
        </div>
        <hr class="mt-1 mb-4" />
        <div class="row mb-3">
          <div class="col-6">
            <b-table-simple>
              <b-thead class="bg-linear-secondary text-gray-primary">
                <b-tr>
                  <b-th class="text-uppercase text-sm">Fecha Inicio</b-th>
                  <b-th class="text-uppercase text-sm text-right"
                    >JUNE 09, 2021</b-th
                  >
                </b-tr>
              </b-thead>
              <b-tbody class="text-gray-primary">
                <b-tr>
                  <b-td>Ahorro mensual</b-td>
                  <b-td v-if="!isEditing" class="text-right"
                    >{{
                      Number(planDeAhorro.plan_ahorros.ahorro_mensual).toFixed(
                        2
                      )
                    }}
                    €</b-td
                  >
                  <input
              v-else
              type="number"
              class="form-control mt-1"
              v-model="editAhorroMensual"
            />
                </b-tr>
                <b-tr>
                  <b-td>Capital inicial</b-td>
                  <b-td class="text-right"
                    >{{
                      Number(planDeAhorro.plan_ahorros.capital_inicial).toFixed(
                        2
                      )
                    }}
                    €</b-td
                  >
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div class="col-6">
            <b-table-simple>
              <b-thead class="bg-linear-secondary text-gray-primary">
                <b-tr>
                  <b-th class="text-uppercase text-sm"
                    >Capital reserva vigente</b-th
                  >
                  <b-th class="text-uppercase text-sm text-right"
                    >{{
                      Number(
                        planDeAhorro.plan_ahorros.capital_reserva_vigente
                      ).toFixed(2)
                    }}
                    €</b-th
                  >
                </b-tr>
              </b-thead>
            </b-table-simple>
          </div>
        </div>
        <div class="row">
          <div class="col-8 mx-auto">
            <b-table-simple>
              <b-thead class="bg-linear-secondary text-gray-primary">
                <b-tr>
                  <b-th colspan="2" class="text-uppercase text-sm text-center"
                    >Calculadora de participaciones periódicas</b-th
                  >
                </b-tr>
              </b-thead>
              <b-tbody class="text-gray-primary">
                <b-tr>
                  <b-td>Precio actual</b-td>
                  <b-td class="text-right"
                    >{{ Number(planDeAhorro.precio_actual).toFixed(2) }} €</b-td
                  >
                </b-tr>
                <b-tr>
                  <b-td>N° de participaciones</b-td>
                  <b-td class="text-right">{{
                    planDeAhorro.participaciones_periodicas
                  }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="row">
          <div class="col-8 mx-auto">
            <ul class="mb-6">
              <li
                v-if="planDeAhorro.capital_inicial > 5000"
                class="text-gray-500 text-sm mb-1"
              >
                * Como su capital inicial es mayor a 5000€, la mitad de dicho
                capital formará parte del importe periódico durante los primeros
                18 meses, es decir, hasta el
                {{
                  $moment(planDeAhorro.plan_ahorros.fecha_revision).format(
                    "DD/MM/YYYY"
                  )
                }}, el resto del capital estará incluido en el capital reserva.
              </li>
              <li v-else class="text-gray-500 text-sm mb-1">
                * Próxima revisión del capital reserva el
                {{
                  $moment(planDeAhorro.plan_ahorros.fecha_revision).format(
                    "DD/MM/YYYY"
                  )
                }}.
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-table-simple>
              <b-thead
                class="bg-linear-secondary text-gray-primary text-gray-primary"
              >
                <b-tr>
                  <b-th class="text-uppercase text-sm text-left">ISIN</b-th>
                  <b-th class="text-uppercase text-sm text-left">VALOR</b-th>
                  <b-th class="text-uppercase text-sm text-left"
                    >IMPORTE PERIÓDICO</b-th
                  >
                  <b-th class="text-uppercase text-sm text-left"
                    >ÚLTIMO MÁXIMO HISTÓRICO</b-th
                  >
                  <b-th class="text-uppercase text-sm text-left"
                    >FECHA ÚLTIMO MÁX. HISTÓRICO</b-th
                  >
                </b-tr>
              </b-thead>
              <b-tbody class="text-gray-primary">
                <b-tr>
                  <b-td>{{ planDeAhorro.plan_ahorros.etf.isin }}</b-td>
                  <b-td
                    class="text-truncate"
                    :title="planDeAhorro.plan_ahorros.etf.descripcion"
                  >
                    {{ planDeAhorro.plan_ahorros.etf.descripcion }}
                  </b-td>
                  <b-td class="text-right"
                    >{{
                      Number(
                        planDeAhorro.plan_ahorros.importe_periodico
                      ).toFixed(2)
                    }}
                    €</b-td
                  >
                  <b-td class="text-right"
                    >{{
                      Number(planDeAhorro.maximo_historico).toFixed(2)
                    }}
                    €</b-td
                  >
                  <b-td class="text-right">
                    {{
                      $moment(planDeAhorro.fecha_maximo_historico).format(
                        "DD/MM/YYYY"
                      )
                    }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-table-simple>
              <b-thead class="bg-linear-secondary text-gray-primary">
                <b-tr>
                  <b-th class="text-uppercase text-sm text-center"
                    >Barrera 10,00%</b-th
                  >
                  <b-th class="text-uppercase text-sm text-center"
                    >Barrera 18,50%</b-th
                  >
                  <b-th class="text-uppercase text-sm text-center"
                    >Barrera 34,50%</b-th
                  >
                  <b-th class="text-uppercase text-sm text-center"
                    >Barrera 50,00%</b-th
                  >
                  <b-th class="text-uppercase text-sm text-center"></b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="text-gray-primary">
                <b-tr>
                  <b-td
                    class="text-center"
                    v-for="(barrera, key) in planDeAhorro.barreras"
                    :key="key"
                    >{{ barrera.participaciones }}</b-td
                  >
                  <b-td
                    class="text-center bg-linear-secondary text-gray-primary"
                    >Participantes</b-td
                  >
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-center"
                    v-for="(barrera, key) in planDeAhorro.barreras"
                    :key="key"
                    >{{ Number.parseFloat(barrera.precio).toFixed(2) }} €</b-td
                  >
                  <b-td
                    class="text-center bg-linear-secondary text-gray-primary"
                    >Precio</b-td
                  >
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../axios";

export default {
  data() {
    return {
      planesDeAhorro: [],
      isEditing: false,

      editNombre: '',
      editAhorroMensual: '',
    };
  },
  created() {
    this.fetchPlanAhorrosCliente(this.$route.params.id_cliente);
  },
  methods: {
    fetchPlanAhorrosCliente(id_cliente) {
      axios
        .get(`/users/saving_plans/${id_cliente}`)
        .then((res) => {
          // console.log(res)
          this.planesDeAhorro = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    edit(planDeAhorro) {
      console.log(planDeAhorro);
      this.editNombre = planDeAhorro.plan_ahorros.nombre;
      this.editAhorroMensual = planDeAhorro.plan_ahorros.ahorro_mensual;
      this.isEditing = true;
    },
    update(plan_id) {
      axios
        .put(`/saving_plans/${plan_id}`, {
          nombre: this.editNombre,
          ahorro_mensual: this.editAhorroMensual,
          user_id: this.$route.params.id_cliente,
        })
        .then(res => {
          console.log(res);
          this.fetchPlanAhorrosCliente(this.$route.params.id_cliente);
          this.isEditing = false;
          this.$bvToast.toast(res.response.data.message, {
            title: `Éxito`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: `¡Error!`,
            variant: "danger",
            solid: true,
          });
        });
    }
  },
};
</script>

<style>
</style>