<template>
  <div class="app-login-wrapper">
    <div class="app-login">
      <b-card :class="{ 'reset-card': !cardStyles }">
        <div class="container">
          <div class="row mb-4">
            <div class="col-lg-12">
              <div class="text-center">DreamerAnt</div>
            </div>
          </div>
          <b-alert v-model="loginError" variant="danger">
            <p class="mb-0 text-center text-sm">
              <!-- <span class=""><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4 h-4"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg></span> -->
              {{ loginErrorMessage }}
            </p>
          </b-alert>
          <b-form @submit.prevent="login">
            <div class="row">
              <div class="col-lg-12">
                <b-form-group>
                  <b-form-input
                    placeholder="Usuario"
                    v-model="email"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-form-input
                  type="password"
                  placeholder="Contraseña"
                  v-model="password"
                ></b-form-input>
              </div>
            </div>
            <b-button type="submit" class="mt-4" variant="success" block>{{
              loginResponseLoading ? "....Espere" : "Ingresar"
            }}</b-button>
          </b-form>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cardStyles: false,
      email: "",
      password: "",
      loginResponseLoading: false,
      loginError: false,
      loginErrorMessage: null,
    };
  },
  created() {
    this.matchMediaQuery();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    matchMediaQuery() {
      const mq = window.matchMedia("(min-width: 400px)");
      if (mq.matches) {
        this.cardStyles = true;
      }
    },
    onResize() {
      if (window.innerWidth > 400) {
        this.cardStyles = true;
      } else {
        this.cardStyles = false;
      }
    },
    login() {
      this.loginResponseLoading = true;
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.loginResponseLoading = false;
          this.loginError = false;
          this.$router.push("/usuarios");
        })
        .catch((err) => {
          this.loginResponseLoading = false;
          if (err.response.status === 401) {
            this.loginErrorMessage =
              "Credenciales incorrectas. Intente nuevamente.";
            this.loginError = true;
          }
          console.log(err.response);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-login-wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
}

.app-login {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  align-self: center;
}
</style>