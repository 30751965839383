<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-md-4 col-lg-4">
          <h1 class="text-2xl mb-0">Nueva noticia</h1>
        </div>
        <div class="col-md-8 col-lg-8 text-right">
          <b-button variant="outline-primary" class="mr-3 btn-action btn-guardar" @click="savePost">Guardar</b-button>
          <b-button variant="success" class="btn-action btn-publicar" @click="createPost">Publicar</b-button>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row">
        <div class="col-lg-8">
          <div class="row mb-3">
            <div class="col-lg-12">
              <b-form-input
                placeholder="Ingresar Titulo"
                v-model="titulo"
              ></b-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <Editor
                :api-key="editor_key"
                v-model="content"
                id="blog-editor"
                :init="options"
              ></Editor>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row mb-3">
            <div class="col-lg-12">
              <h6>Descripción</h6>
              <b-form-textarea rows="3" v-model="resume"></b-form-textarea>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-12">
              <h6>Imagen principal</h6>
              <div class="border rounded bg-white">
                <div class="w-full">
                  <div class="aspect-3-1 overflow-hidden position-relative">
                    <img
                      v-if="url"
                      :src="url"
                      alt=""
                      class="w-100 h-100 object-cover position-absolute top-0 left-0"
                    />
                  </div>
                </div>
                <b-form-file
                  placeholder="Cargar imagen"
                  v-model="mainImage"
                  @change="handlePreview"
                ></b-form-file>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-12">
              <h6>Fecha de publicación</h6>
                <b-form-datepicker id="example-datepicker" placeholder="Publicar de inmediato" v-model="created_at" class="mb-2"></b-form-datepicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import UploadPreviewImage from "../components/UploadPreviewImage";
import axios from "@/axios";

export default {
  components: {
    Editor,
    // UploadPreviewImage,
  },
  data() {
    return {
      titulo: "",
      content: "",
      resume: "",
      created_at: "",
      mainImage: undefined,
      url: null,
      editor_key: process.env.VUE_APP_EDITOR_KEY,
      options: {
        height: 500,
        menubar: "view table",
        plugins: [
          "advlist autolink lists link image charmap",
          "searchreplace visualblocks code fullscreen",
          "print preview anchor insertdatetime media",
          "paste code help wordcount table",
        ],
        toolbar:
          "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | preview | help image | media | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
        image_title: true,
        automatic_uploads: true,
        file_picker_type: "image",
        media_dimensions: false,
        media_live_embeds: true,
        table_default_styles: {
          'border-collapse': 'collapse',
          'width': '100%'
        },
        table_header_type: 'sectionCells',
        images_upload_handler: function (blobInfo, success, failure) {
          var formData;
          formData = new FormData()
          formData.append('image', blobInfo.blob(), blobInfo.filename())
          axios.post("posts/upload", formData)
          .then(res => {
            // console.log(res.data.image_path)
            success(`${process.env.VUE_APP_API_PUBLIC}${res.data.data.image_path}`)
          }).catch(err => {
            failure(err.response)
          })
        },
        media_url_resolver: function (data, resolve/*, reject*/) {
          let embedHtml = `<div class="aspect-w-16 aspect-h-9">
          <iframe src="${data.url}" width="560" height="314"></iframe></div>`
          resolve({html: embedHtml});
        },
        
      },
    };
  },
  methods: {
    createPost() {
      let formData = new FormData();
      formData.append("titulo", this.titulo);
      formData.append("categoria", "noticias");
      formData.append("contenido", this.content);
      formData.append("resumen", this.resume);
      formData.append("visible", 1)
      formData.append("img_principal", this.mainImage);
      console.log()
      if(this.created_at !== "") formData.append("created_at", this.created_at);

      axios
        .post("/posts", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          this.$router.push("/noticias");
        })
        .catch((err) => {
          console.log("ERRROR");
          this.$bvToast.toast(err.message, {
            title: 'Oops!',
            variant: 'danger',
            solid: true
          });
        });
    },
    savePost() {
      let formData = new FormData();
      formData.append("titulo", this.titulo);
      formData.append("categoria", "noticias");
      formData.append("contenido", this.content);
      formData.append("resumen", this.resume);
      formData.append("visible", 0)
      formData.append("img_principal", this.mainImage);

      axios
        .post("/posts", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          this.$router.push("/noticias");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePreview(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
  },
};
</script>

<style scoped>
.aspect-3-1 {
  padding-top: calc(
    (9 / 16) * 100%
  ); /* 16:9 Aspect ratio (dividir 9 entre 16 = 0.5625 equivale a 56.25%) */
}

.btn-guardar, .btn-publicar {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .btn-guardar, .btn-publicar {
    width: auto;
  }
}
</style>