<template>
  <div class="mt-4 panel-cliente">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>Panel de {{ user.name }}</h2>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
    </div>
    <div class="container">
      <div class="row mb-3">
        <div class="col-12">
          <b-card title="Seguimiento">
            <hr class="mt-1 mb-4" />
            <div class="row" v-if="error === 'SAVING_PLAN_NOT_FOUND'">
              <div class="col-12">
                <b-alert show variant="danger">Aún no tiene un plan de ahorros para visualizar el seguimiento.</b-alert>
              </div>
            </div>
            <bar-chart :options="barChartOptions" :chartData="seguimientoData"></bar-chart>
          </b-card>
        </div>
      </div>
      <div class="row mb-3 panel-cliente-nav">
        <div class="col-12">
          <b-nav tabs>
            <b-nav-item :to="{ name: 'AhorrosCliente', params: { id_cliente: $route.params.id_cliente } }" exact>Mis ahorros</b-nav-item>
            <b-nav-item :to="{ name: 'PlanAhorrosCliente', params: { id_cliente: $route.params.id_cliente } }" exact>Plan de ahorros</b-nav-item>
            <b-nav-item :to="{ name: 'MovimientosCliente', params: { id_cliente: $route.params.id_cliente } }" exact>Movimientos</b-nav-item>
          </b-nav>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <b-card>
            <router-view></router-view>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
import BarChart from '../components/BarChart.vue'

export default {
  components: { BarChart },
  data() {
    return {
      user: {},
      barChartOptions: {
        legend: { position: 'bottom', usePointStyle: true },
        maintainAspectRatio: false,
        responsive: true,
        title: { display: false },
        // tooltips: { mode: 'index', intersect: false },
        hover: { mode: 'nearest', intersect: true },
        scales: {
          xAxes: [{ display: true, stacked: true, scaleLabel: { display: false, labelString: 'time' } }],
          yAxes: [{
            type: 'linear',
            id: 'left-axis',
            display: true,
            position: 'left',
            scaleLabel: { display: true, labelString: 'Capital' }
          }, {
            type: 'linear',
            id: 'right-axis',
            display: true,
            position: 'right',
            stacked: false,
            scaleLabel: { display: true, labelString: 'Rentabilidad (%)' },
            gridLines: { drawOnChartArea: false }
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var currentValue = dataset.data[tooltipItem.index];
              if(tooltipItem.datasetIndex == 0) {
                return currentValue + '%';
              }
              return currentValue;
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        }
      },
      seguimientoData: {},
      error: null
    }
  },
  created() {
    this.fetchDataUsuario(this.$route.params.id_cliente)
    this.fetchSeguimientoUsuario(this.$route.params.id_cliente)
  },
  methods: {
    fetchDataUsuario(id_cliente) {
      axios.get(`/users/${id_cliente}`)
      .then(res => {
        console.log(res)
        this.user = res.data.user
      }).catch(err => {
        console.log(err)
      })
    },
    fetchSeguimientoUsuario(id_cliente) {
      axios.get(`/users/my-trace/${id_cliente}`)
      .then(res => {
        console.log(res)
        const labels = res.data.data.map((o) =>{
          let date = this.$moment(new Date(o.created_at))
          date = date.subtract(1, 'd')
          
          return date.format('MMMM')
        })
        const rentabilidadData = res.data.data.map((o) => o.rentabilidad.toFixed(2))
        const capitalData = res.data.data.map((o) => o.valor_actual.toFixed(2))

        this.seguimientoData = {
          labels: labels,
          datasets: [
            {
              type: 'line',
              label: 'Rentabilidad',
              fill: false,
              backgroundColor: '#C0D0FF',
              borderColor: '#7799FF',
              borderWidth: 5,
              pointBackgroundColor: '#FFF',
              data: rentabilidadData,
              tension: 0.4,
              order: 1,
              yAxisID: 'right-axis',
              // yAxisID: 'y',
            },
            {
              label: 'Capital',
              fill: true,
              backgroundColor: '#FFAE77',
              borderColor: '#0c0306',
              data: capitalData,
              order: 2,
              yAxisID: 'left-axis',
            },
          ],
        }
      }).catch(err => {
        console.log(err)
        this.error = err.response.data.error
      })

    }
  }
}
</script>

<style scoped>
.panel-cliente-nav .nav-link.router-link-exact-active {
  background: #fff;
  color: #495057;
  border-color: #dee2e6 #dee2e6 #fff;
}
</style>