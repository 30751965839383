var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-4 mb-3 align-items-baseline"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-6 col-lg-8 text-right"},[_c('b-button',{staticClass:"btn-action",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$router.push({
              name: 'CrearFaq',
              params: {
                text: 'crear',
                texto: $event.target.textContent
              },
            })}}},[_vm._v("Nueva FAQ")])],1)]),_c('hr',{staticClass:"mt-1 mb-4"}),_c('div',{staticClass:"row align-items-baseline mb-3"},[_c('div',{staticClass:"col-lg-4"},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" Total: "),_c('span',{staticClass:"font-weight-normal"},[_vm._v(_vm._s(_vm.faqsItems.length))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('b-table',{attrs:{"items":_vm.faqsItems,"fields":_vm.faqFields,"striped":"","outlined":"","responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(acciones)",fn:function(data){return [_c('b-button',{staticClass:"btn-reset",on:{"click":function($event){return _vm.$router.push({
                  name: 'CrearFaq',
                  params: {
                    text: 'editar',
                    idFaqEdit: data.item.faq_id,
                    texto: $event.target.textContent,
                    pregunta: data.item.pregunta,
                    respuesta: data.item.respuesta
                  }
                })}}},[_c('svg',{staticClass:"w-4 h-4 fill-current text-blue-500",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"}})])]),_c('b-button',{staticClass:"btn-reset",on:{"click":function($event){return _vm.eliminarFaq(data.item.faq_id)}}},[_c('svg',{staticClass:"w-4 h-4 fill-current text-red-500",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-4"},[_c('h1',{staticClass:"text-2xl mb-0"},[_vm._v("FAQs")])])}]

export { render, staticRenderFns }