import Vue from "vue";
import VueRouter from "vue-router"
import store from "../store"
import RouterMultiguard from 'vue-router-multiguard'

import Home from "../views/Home.vue"
import Newsletter from "../views/Newsletter"
import Noticias from "../views/Noticias"
import CrearNoticia from "../views/CrearNoticia"
import EditarNoticia from "../views/EditarNoticia"
import Etfs from "../views/Etfs"
import CrearEtf from "../views/CrearEtf"
import EditarEtf from "../views/EditarEtf"
import DetalleEtf from "../views/DetalleEtf"
import Usuarios from "../views/Usuarios"
import CrearUsuario from "../views/CrearUsuario"
import Testmonios from "../views/Testimonios"
import Faqs from "../views/Faqs";
import CrearFaq from "../views/CrearFaq"
import Login from "@/components/Login"
import PanelCliente from "../views/PanelCliente"
import AhorrosCliente from "../views/AhorrosCliente"
import PlanAhorrosCliente from "../views/PlanAhorrosCliente"
import MovimientosCliente from "../views/MovimientosCliente"

Vue.use(VueRouter);

const isLoggedIn = function (to, from, next) {
  if (!store.getters['loggedIn']) {
    return next({
      name: 'Login'
    })
  }
  next()
}

const isAuthenticated = function (to, from, next) {
  if (store.getters['loggedIn']) {
    return next(from.path)
  }
  next()
}

const isAdmin = function (to, from, next) {
  if (store.getters['user'].rol === 'administrador') {
    next()
  }
}

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: RouterMultiguard([isAuthenticated])
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: Newsletter,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/noticias",
    name: "Noticias",
    component: Noticias,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/noticias/crear",
    name: "CrearNoticia",
    component: CrearNoticia,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/noticias/:idNoticia/editar",
    name: "EditarNoticia",
    component: EditarNoticia,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/etfs",
    name: "Etfs",
    component: Etfs,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/etfs/crear",
    name: "CrearEtf",
    component: CrearEtf,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/etfs/:idEtf/editar",
    name: "EditarEtf",
    component: EditarEtf,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/etfs/:idEtf",
    name: "DetalleEtf",
    component: DetalleEtf,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/usuarios/crear",
    name: "CrearUsuario",
    component: CrearUsuario,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/usuarios/:id_cliente",
    name: "PanelCliente",
    component: PanelCliente,
    redirect: '/usuarios/:id_cliente/mis-ahorros',
    children: [
      {
        path: "/usuarios/:id_cliente/mis-ahorros",
        name: "AhorrosCliente",
        component: AhorrosCliente,
        beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
      },
      {
        path: "/usuarios/:id_cliente/mi-plan-ahorros",
        name: "PlanAhorrosCliente",
        component: PlanAhorrosCliente,
        beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
      },
      {
        path: "/usuarios/:id_cliente/mis-movimientos",
        name: "MovimientosCliente",
        component: MovimientosCliente,
        beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
      },
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/testimonios",
    name: "Testimonios",
    component: Testmonios,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  },
  {
    path: "/faqs/:text",
    name: "CrearFaq",
    component: CrearFaq,
    beforeEnter: RouterMultiguard([isLoggedIn, isAdmin])
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
