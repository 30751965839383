<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-lg-4">
          <h1 class="text-2xl mb-0">Testimonios</h1>
        </div>
        <!-- <div class="col-lg-8 text-right">
          <b-button variant="success" to="/noticias/crear" class="btn-action"
            >Nueva noticia</b-button
          >
        </div> -->
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row align-items-baseline mb-3">
        <div class="col-lg-4">
          <p id="ejemplo" class="mb-0 font-weight-bold">
            Total:
            <span class="font-weight-normal">{{
              testimoniosPagination.total
            }}</span>
          </p>
        </div>
        <!-- <div class="col-lg-4 offset-lg-4">
          <b-form-input placeholder="Buscar por titulo ..." v-model="queryTitulo" @update="searchNoticias"></b-form-input>
        </div> -->
      </div>
      <div class="row">
        <div class="col-lg-12">
          <b-table
            :items="testimoniosItems"
            :fields="testimoniosFields"
            striped
            outlined
            responsive
            show-empty
          >
            <template #empty>
              <p class="text-center">No existen testimonios</p>
            </template>
            <template #cell(index)="data">
              {{ data.index + testimoniosPagination.from }}
            </template>
            <template #cell(visible)="data">
              <b-badge v-if="data.visible" variant="secondary"
                >Borrador</b-badge
              >
              <b-badge v-else variant="success">Publicado</b-badge>
            </template>
            <template #cell(editar)="data">
              <!--Boton agregar texto en testimonio-->
              <div
                :key="data.index"
                v-if="!data.item.testimony"
                class="btn-center"
              >
                <button
                  @click="showCreateModal(data.item.name, data.item.id)"
                  v-b-modal.crear-testimonio-modal
                  class="btn-add-testimonies"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 fill-current text-white-500 hover-svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    ></path>
                  </svg>
                </button>
              </div>
              <div
                :key="data.index"
                v-if="data.item.testimony"
                class="btn-center"
              >
                <!--Agregar editar-->
                <b-button
                  class="btn-reset"
                  title="Editar cliente"
                  @click="
                    showCreateModalEdit(
                      data.item.name,
                      data.item.testimony.texto,
                      data.item.testimony.testimonies_id
                    )
                  "
                >
                  <svg
                    class="w-4 h-4 fill-current text-blue-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    ></path></svg
                ></b-button>
                <!--Btn eliminar-->
                <b-button
                  class="btn-reset"
                  @click="
                    confirmDeleteTestimonies(
                      data.item.name,
                      data.item.testimony.testimonies_id
                    )
                  "
                  ><svg
                    class="w-4 h-4 fill-current text-red-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    ></path></svg
                ></b-button>
                <!--Fin editar y eliminar-->
              </div>
            </template>
            <template #cell(acciones)="data">
              <b-form-checkbox
                v-if="data.item.testimony"
                v-model="data.item.visible"
                size="lg"
                name="check-button"
                switch
                @change="
                  updateTestimonio($event, data.item.testimony.testimonies_id)
                "
              ></b-form-checkbox>
            </template>
          </b-table>
        </div>
        <div class="col-lg-12">
          <b-pagination
            v-if="currentPagePagination"
            v-model="currentPagePagination"
            :total-rows="testimoniosPagination.total"
            :per-page="testimoniosPagination.per_page"
            align="right"
            @change="changePage"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal
      ref="crear-testimonio-modal"
      title="Nuevo testimonio"
      cancel-title="Cancelar"
      ok-title="Guardar"
      @ok="createTestimony"
    >
      <p class="text-lg">Agregando testimonio a {{ this.userName }}</p>
      <b-form-textarea
        id="textarea"
        v-model="newTestimonyText"
        placeholder="Redacta testimonio"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-modal>

    <b-modal
      ref="actualizar-testimonio-modal"
      title="Actualizar testimonio"
      cancel-title="Cancelar"
      ok-title="Guardar"
      @ok="updateTestimonyTextAndVisible"
    >
      <p class="text-lg">Editando testimonio de {{ this.userName }}</p>
      <b-form-textarea
        id="textarea"
        v-model="userNewTexto"
        placeholder="Redacta testimonio"
        v-text="userNewTexto"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
      <br />
      <b-form-checkbox v-model="checkFalse" name="check-button" switch>
        <b v-if="checkFalse">Testimonio Visible</b>
        <b v-if="!checkFalse">Testimonio No Visible</b>
      </b-form-checkbox>
    </b-modal>
    <!-- Alerta success-->
    <b-alert
      v-if="this.statusCode === 201 || this.statusCode === 200"
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ this.responseText }}</p>
    </b-alert>
    <!-- Aler danger -->
    <b-alert
      v-if="this.statusCode === 422"
      :show="dismissCountDown"
      dismissible
      variant="danger"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ this.responseText }}</p>
    </b-alert>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      testimoniosFields: [
        {
          label: "",
          key: "index"
        },
        {
          label: "Usuario",
          key: "name"
        },
        {
          label: "Texto",
          key: "testimony.texto"
        },
        {
          label: "", //editar
          key: "editar",
          thClass: "text-right acciones-th",
          tdClass: "text-right acciones-td"
        },
        {
          label: "Visible",
          key: "acciones",
          thClass: "text-right acciones-th",
          tdClass: "text-right acciones-td"
        }
      ],
      testimoniosItems: [],
      testimoniosPagination: {},
      queryTitulo: null,
      currentPagePagination: null,
      checkTrue: true,
      checkFalse: false,
      newTestimonyText: "",
      selectedUserId: null,
      userName: "", //capturar nombre
      returButtonDelete: false, //Capturar respuesta de eliminar
      userNewTexto: "", //Variable usaada para editar texo
      userTestimoniesId: null, //Variable para capturar el id del testimonio a editar
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      statusCode: 0,
      responseText: ""
    };
  },
  created() {
    this.fetchTestimonios();
  },
  methods: {
    fetchTestimonios() {
      axios
        .get("testimonies/admin", {
          params: {
            page: this.$route.query.page
          }
        })
        .then(res => {
          this.testimoniosItems = res.data.data.data.map(o => {
            if (o.testimony !== null) {
              if (o.testimony.visible == 1) {
                o.visible = true;
              } else o.visible = false;
            }
            return o;
          });
          this.testimoniosPagination.total = res.data.data.total;
          this.testimoniosPagination.per_page = res.data.data.per_page;
          this.testimoniosPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;

          if (this.$route.query.titulo)
            this.queryTitulo = this.$route.query.titulo;
          if (this.$route.query.page)
            this.currentPagePagination = Number(this.$route.query.page);
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteTestimonies(data) {
      axios
        .delete(`testimonies/${data}`)
        .then(res => {
          this.responseText = res.data.message;
          this.statusCode = res.status;
          this.fetchTestimonios();
          this.showAlert();
        })
        .catch(err => {
          console.error(err);
        });
    },
    createTestimony() {
      axios
        .post("testimonies", {
          texto: this.newTestimonyText,
          user_id: this.selectedUserId
        })
        .then(res => {
          this.$refs["crear-testimonio-modal"].hide();
          this.newTestimonyText = "";
          this.responseText = res.data.message;
          this.statusCode = res.status;
          this.showAlert();
          this.fetchTestimonios();
        })
        .catch(error => {
          this.statusCode = error.response.status;
          this.responseText = error.response.data.errors.texto[0];
          this.showAlert();
        });
    },
    changePage(page) {
      axios
        .get("testimonies/admin", {
          params: {
            page,
            titulo: this.$route.query.titulo
          }
        })
        .then(res => {
          this.$router.push({
            path: "testimonios",
            query: { page, titulo: this.$route.query.titulo }
          });
          this.testimoniosItems = res.data.data.data.map(o => {
            if (o.visible == 1) {
              o.visible = true;
            } else o.visible = false;
            return o;
          });
          this.testimoniosPagination.total = res.data.data.total;
          this.testimoniosPagination.per_page = res.data.data.per_page;
          this.testimoniosPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateTestimonio(event, testimonies_id) {
      axios
        .put("testimonies/" + testimonies_id, {
          visible: event
        })
        .then(() => {
          this.fetchTestimonios();
        })
        .catch(err => {
          console.error(err);
        });
    },
    updateTestimonyTextAndVisible() {
      axios
        .put("testimonies/" + this.userTestimoniesId, {
          texto: this.userNewTexto,
          visible: this.checkFalse
        })
        .then(res => {
          this.statusCode = res.status;
          this.responseText = res.data.message;
          this.$refs["actualizar-testimonio-modal"].hide();
          this.showAlert();
          this.fetchTestimonios();
          this.checkFalse = false;
        })
        .catch(error => {
          this.statusCode = error.response.status;
          this.responseText = error.response.data.errors.texto[0];
          this.showAlert();
        });
    },
    confirmDeleteTestimonies(name, testimoniesId) {
      this.$bvModal
        .msgBoxConfirm(`Desea borrar el testimonio de ${name}`, {
          title: "Eliminar Testimonio",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.returButtonDelete = value;
            this.deleteTestimonies(testimoniesId);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    showCreateModalEdit(name, texto, testimoniesId) {
      this.userName = name;
      this.userNewTexto = texto;
      this.userTestimoniesId = testimoniesId;
      this.$refs["actualizar-testimonio-modal"].show();
    },
    showCreateModal(name, userId) {
      this.selectedUserId = userId;
      this.userName = name;
      this.$refs["crear-testimonio-modal"].show();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  }
};
</script>

<style scoped>
.text-white-500 {
  color: var(--white);
}

.btn-center {
  display: flex;
  justify-content: center;
}

.btn-add-testimonies {
  color: #037c17 !important;
  font-size: 3px;
  font-weight: 500;
  padding: 0.5em 1.2em;
  background: rgba(0, 0, 0, 0);
  border: 2px solid;
  border-radius: 50%;
  background: #037c17;
  transition: all 1s ease;
  position: relative;
}

.btn-add-testimonies:hover {
  background-color: transparent;
  border-color: #31ac3b;
  color: #fff !important;
}

.hover-svg:hover {
  color: #037c17;
}
</style>
