<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-12 col-md-6 col-lg-4">
          <h1 class="text-2xl mb-0">ETFs</h1>
        </div>
        <div class="col-12 col-md-6 col-lg-8 text-right">
          <b-button variant="success" to="/etfs/crear" class="btn-action">Nuevo ETF</b-button>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row">
        <div class="col-lg-12">
          <b-table
            :items="etfsItems"
            :fields="etfsFields"
            striped
            outlined
            responsive
            show-empty
          >
            <template #empty>
              <p class="text-center">No existen registro de ETFS</p>
            </template>
            <template #cell(index)="data">
              {{ data.index + etfsPagination.from }}
            </template>
            <template #cell(acciones)="data">
              <b-button class="btn-reset" @click="$router.push({
                name: 'DetalleEtf',
                params: {
                  idEtf: data.item.etf_id,
                  dataEtf: data.item
                }
              })"
                ><svg
                  class="w-4 h-4 fill-current text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  ></path></svg
              ></b-button>
              <b-button
                class="btn-reset"
                @click="
                  $router.push({
                    name: 'EditarEtf',
                    params: {
                      idEtf: data.item.etf_id,
                      dataEtf: data.item
                    },
                  })
                "
                ><svg
                  class="w-4 h-4 fill-current text-blue-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path></svg
              ></b-button>
              <b-button class="btn-reset" @click="eliminarEtf(data.item.etf_id)"
                ><svg
                  class="w-4 h-4 fill-current text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path></svg
              ></b-button>
            </template>
          </b-table>
        </div>
        <div class="col-lg-12">
          <b-pagination
            v-if="currentPagePagination"
            v-model="currentPagePagination"
            :total-rows="etfsPagination.total"
            :per-page="etfsPagination.per_page"
            align="right"
            @change="changePage"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      etfsFields: [
        {
          label: "",
          key: "index",
        },
        {
          label: "Identificador",
          key: "identificador",
        },
        {
          label: "",
          key: "acciones",
          thClass: "text-right acciones-th",
          tdClass: "text-right acciones-td",
        },
      ],
      etfsItems: [],
      etfsPagination: {},
      currentPagePagination: null,
    };
  },
  created() {
    this.fetchEtfs();
  },
  methods: {
    fetchEtfs() {
      axios
        .get("etfs")
        .then((res) => {
          this.etfsItems = res.data.data.data;
          this.etfsPagination.total = res.data.data.total;
          this.etfsPagination.per_page = res.data.data.per_page;
          this.etfsPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(page) {
      axios
        .get("etfs", {
          params: {
            page,
          },
        })
        .then((res) => {
          console.log(res);
          this.etfsItems = res.data.data.data;
          this.etfsPagination.total = res.data.data.total;
          this.etfsPagination.per_page = res.data.data.per_page;
          this.etfsPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    eliminarEtf(etf_id) {
      this.$bvModal.msgBoxConfirm('Al confirmar la eliminación, se eliminará toda la información relacionada al ETF.', {
        title: 'Confirmar eliminación',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmar',
        cancelVariant: 'outline-primary',
        cancelTitle: 'Cancelar',
        centered: true
      }).then(value => {
        if (value) {
          axios.delete(`etfs/${etf_id}`)
          .then(res => {
            console.log(res)
            this.fetchEtfs()
          }).catch(err => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.error(`Ocurió un error: ${err}`)
      })
    }
  },
};
</script>

<style>
</style>