<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-lg-4">
          <h1 class="text-2xl mb-0">Detalle del ETF</h1>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row">
        <div class="col-md-5 col-lg-5">
          <div class="row">
            <div class="col-lg-6 text-right">
              <p class="">Identificador del ETF:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.identificador }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>ISIN:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.isin }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>Gestora:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.gestora }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>Imagen de gestora:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.gestora_imagen }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>Patrimonio:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.patrimonio }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>Mercado:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.mercado }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>Bolsa:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.bolsa }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>Symbol:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.symbol_google }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-right">
              <p>Gratis de giro:</p>
            </div>
            <div class="col-lg-6">
              <p>{{ etf.gratis_degiro }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-lg-7">
          <b-table
            :items="valuesEtfItems"
            :fields="valuesEtfFields"
            striped
            outlined
            responsive
            show-empty
          >
            <template #empty>
              <p class="text-center">No existen valores asociados al ETF</p>
            </template>
            <template #cell(index)="data">
              {{ data.index + etfsPagination.from }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  data() {
    return {
      etf: {
        identificador: "",
        gestora: "",
        gestora_imagen: "",
        isin: "",
        patrimonio: "",
        mercado: "",
        bolsa: "",
        symbol_google: "",
        descripcion: "",
        gratis_degiro: false,
      },
      valuesEtfFields: [
        {
          label: "Fecha",
          key: "created_at",
        },
        {
          label: "Subida",
          key: "alto",
        },
        {
          label: "Bajada",
          key: "bajo",
        },
        {
          label: "Cierre",
          key: "cierre",
        },
      ],
      valuesEtfItems: [],
    };
  },
  created() {
    if (this.$route.params.dataEtf) {
      let etf = this.$route.params.dataEtf
      this.etf.identificador = etf.identificador;
      this.etf.gestora = etf.gestora;
      this.etf.gestora_imagen = etf.gestora_imagen;
      this.etf.isin = etf.isin;
      this.etf.patrimonio = etf.patrimonio;
      this.etf.mercado = etf.mercado;
      this.etf.bolsa = etf.bolsa;
      this.etf.symbol_google = etf.symbol_google;
      this.etf.descripcion = etf.descripcion;
      this.etf.gratis_degiro = etf.gratis_degiro;
      this.valuesEtfItems = etf.daily_values
    } else {
      this.fetchEtf()
    }
  },
  methods: {
    fetchEtf() {
      axios
        .get(`etfs/${this.$route.params.idEtf}`)
        .then((res) => {
          this.etf.identificador = res.data.data.identificador;
          this.etf.gestora = res.data.data.gestora;
          this.etf.gestora_imagen = res.data.data.gestora_imagen;
          this.etf.isin = res.data.data.isin;
          this.etf.patrimonio = res.data.data.patrimonio;
          this.etf.mercado = res.data.data.mercado;
          this.etf.bolsa = res.data.data.bolsa;
          this.etf.symbol_google = res.data.data.symbol_google;
          this.etf.descripcion = res.data.data.descripcion;
          this.etf.gratis_degiro = res.data.data.gratis_degiro;
          this.valuesEtfItems = res.data.data.daily_values
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>