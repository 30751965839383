import axios from "axios"
import router from './router'


const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('ant_token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // console.log(error.response.status)
  if (error.response.status === 401) {
    localStorage.removeItem('ant_token')
    localStorage.removeItem('ant_user')
    router.push({ name: 'Login' })
  }
  return Promise.reject(error)
})

export default instance