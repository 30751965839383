<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-lg-4">
          <h1 class="text-2xl mb-0">FAQs</h1>
        </div>
        <div class="col-12 col-md-6 col-lg-8 text-right">
          <!--@click="dataprueba($event.target.textContent)" to="/faqs/crear"-->
          <b-button
            variant="success"
            @click="
              $router.push({
                name: 'CrearFaq',
                params: {
                  text: 'crear',
                  texto: $event.target.textContent
                },
              })
            "
            class="btn-action"
            >Nueva FAQ</b-button
          >
        </div>
        <!-- <div class="col-lg-8 text-right">
          <b-button variant="success" to="/noticias/crear" class="btn-action"
            >Nueva noticia</b-button
          >
        </div> -->
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row align-items-baseline mb-3">
        <div class="col-lg-4">
          <p class="mb-0 font-weight-bold">
            Total:
            <span class="font-weight-normal">{{ faqsItems.length }}</span>
          </p>
        </div>
        <!-- <div class="col-lg-4 offset-lg-4">
          <b-form-input placeholder="Buscar por titulo ..." v-model="queryTitulo" @update="searchNoticias"></b-form-input>
        </div> -->
      </div>
      <div class="row">
        <div class="col-lg-12">
          <b-table
            :items="faqsItems"
            :fields="faqFields"
            striped
            outlined
            responsive
            show-empty
          >
            <template #cell(acciones)="data">
              <!-- <b-button class="btn-reset" @click="$router.push({
                name: 'DetalleEtf',
                params: {
                  idEtf: data.item.etf_id,
                  dataEtf: data.item
                }
              })"
                ><svg
                  class="w-4 h-4 fill-current text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  ></path></svg 
              ></b-button> " -->
              <b-button
                class="btn-reset"
                @click="
                  $router.push({
                    name: 'CrearFaq',
                    params: {
                      text: 'editar',
                      idFaqEdit: data.item.faq_id,
                      texto: $event.target.textContent,
                      pregunta: data.item.pregunta,
                      respuesta: data.item.respuesta
                    }
                  })
                "
                ><svg
                  class="w-4 h-4 fill-current text-blue-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path></svg
              ></b-button>
              <b-button class="btn-reset" @click="eliminarFaq(data.item.faq_id)"
                ><svg
                  class="w-4 h-4 fill-current text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path></svg
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      faqFields: [
        {
          label: "",
          key: "index",
        },
        {
          label: "Pregunta",
          key: "pregunta",
        },
        {
          label: "Respuesta",
          key: "respuesta",
        },
        {
          label: "",
          key: "acciones",
          thClass: "text-right acciones-th",
          tdClass: "text-right acciones-td",
        },
      ],
      faqsItems: [],
      checkTrue: true,
      checkFalse: false,
      newTextFaq: "crear",
      editTextFaq: "editar"
    };
  },
  created() {
    this.fetchFaqs();
  },
  methods: {
    fetchFaqs() {
      axios
        .get("faqs/", {
          params: {
            page: this.$route.query.page,
          },
        })
        .then((res) => {
          console.log(res);
          this.faqsItems = res.data.data;
          // this.faqsItems = res.data.data.data.map(o => {
          //     if(o.visible == 1) {
          //         o.visible = true;
          //     } else o.visible = false;
          //     return o;
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    eliminarFaq(faq_id) {
      axios
        .delete(`faqs/${faq_id}`)
        .then((res) => {
          console.log(res);
          this.fetchFaqs();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>
