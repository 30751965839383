<template>
  <div class="">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-lg-4">
          <h1 class="text-2xl mb-0">Noticias</h1>
        </div>
        <div class="col-lg-8 text-right">
          <b-button variant="success" to="/noticias/crear" class="btn-action"
            >Nueva noticia</b-button
          >
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row align-items-baseline mb-3">
        <div class="col-lg-4">
          <p class="mb-0 font-weight-bold">
            Total:
            <span class="font-weight-normal">{{
              noticiasPagination.total
            }}</span>
          </p>
        </div>
        <div class="col-lg-4 offset-lg-4">
          <b-form-input
            placeholder="Buscar por titulo ..."
            v-model="queryTitulo"
            @update="searchNoticias"
          ></b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <b-table
            :items="noticiasItems"
            :fields="noticiasFields"
            striped
            outlined
            responsive
            show-empty
          >
            <template #empty>
              <p class="text-center">No existen noticias</p>
            </template>
            <template #cell(created_at)="data">
              {{ $moment(data.item.created_at).format("lll") }}
            </template>
            <template #cell(index)="data">
              {{ data.index + noticiasPagination.from }}
            </template>
            <template #cell(visible)="data">
              <b-badge v-if="data.item.visible == 1 && $moment(data.item.created_at).diff($moment.now()) > 0" variant="primary"
                >Programado</b-badge
              >
              <b-badge v-else-if="data.item.visible == 0" variant="secondary"
                >Borrador</b-badge
              >
              <b-badge v-else variant="success">Publicado</b-badge>
            </template>
            <template #cell(acciones)="data">
              <b-button
                class="btn-reset"
                @click="
                  $router.push({
                    name: 'EditarNoticia',
                    params: {
                      idNoticia: data.item.post_id,
                    },
                  })
                "
                ><svg
                  class="w-4 h-4 fill-current text-blue-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path></svg
              ></b-button>
              <b-button class="btn-reset" @click="eliminarPost(data.item)"
                ><svg
                  class="w-4 h-4 fill-current text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path></svg
              ></b-button>
            </template>
          </b-table>
        </div>
        <div class="col-lg-12">
          <b-pagination
            v-if="currentPagePagination"
            v-model="currentPagePagination"
            :total-rows="noticiasPagination.total"
            :per-page="noticiasPagination.per_page"
            align="right"
            @change="changePage"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      noticiasFields: [
        {
          label: "",
          key: "index",
        },
        {
          label: "Titulo",
          key: "titulo",
        },
        {
          label: "Fecha de publicación",
          key: "created_at",
        },
        {
          label: "Estado",
          key: "visible",
        },
        {
          label: "",
          key: "acciones",
          thClass: "text-right acciones-th",
          tdClass: "text-right acciones-td",
        },
      ],
      noticiasItems: [],
      noticiasPagination: {},
      queryTitulo: null,
      currentPagePagination: null,
    };
  },
  created() {
    this.fetchNoticias();
  },
  methods: {
    fetchNoticias() {
      axios
        .get("posts/admin", {
          params: {
            page: this.$route.query.page,
            titulo: this.$route.query.titulo,
          },
        })
        .then((res) => {
          this.noticiasItems = res.data.data.data;
          this.noticiasPagination.total = res.data.data.total;
          this.noticiasPagination.per_page = res.data.data.per_page;
          this.noticiasPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;

          if (this.$route.query.titulo)
            this.queryTitulo = this.$route.query.titulo;
          if (this.$route.query.page)
            this.currentPagePagination = Number(this.$route.query.page);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(page) {
      axios
        .get("posts/admin", {
          params: {
            page,
            titulo: this.$route.query.titulo,
          },
        })
        .then((res) => {
          this.$router.push({
            path: "noticias",
            query: { page, titulo: this.$route.query.titulo },
          });
          this.noticiasItems = res.data.data.data;
          this.noticiasPagination.total = res.data.data.total;
          this.noticiasPagination.per_page = res.data.data.per_page;
          this.noticiasPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchNoticias(titulo) {
      axios
        .get("posts/admin", {
          params: {
            titulo,
          },
        })
        .then((res) => {
          this.$router.push({ path: "noticias", query: { titulo } });
          this.noticiasItems = res.data.data.data;
          this.noticiasPagination.total = res.data.data.total;
          this.noticiasPagination.per_page = res.data.data.per_page;
          this.noticiasPagination.from = res.data.data.from;
          this.currentPagePagination = res.data.data.current_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    eliminarPost(post) {
      axios
        .delete(`posts/${post.post_id}`)
        .then((res) => {
          console.log(res);
          this.fetchNoticias();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>