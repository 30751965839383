<template>
  <div class="mt-4">
    <div class="container">
      <div class="row mt-4 mb-3 align-items-baseline">
        <div class="col-lg-4">
          <h2 class="text-xl mb-0">Mis ahorros</h2>
        </div>
      </div>
      <hr class="mt-1 mb-4" />
      <div class="row mb-3">
        <div class="col-12">
          <b-table :items="ahorrosItems" :fields="ahorrosFields" striped outlined responsive show-empty>
            <template #empty>
              <p class="text-center mb-0">No existen ahorros para mostrar.</p>
            </template>
            <template #cell(desembolsos)="data">
              {{ Number(data.item.desembolsos).toFixed(2) }} €
            </template>
            <template #cell(precio_medio)="data">
              {{ Number(data.item.precio_medio).toFixed(2) }} €
            </template>
            <template #cell(precio_actual)="data">
              {{ Number(data.item.precio_actual).toFixed(2) }} €
            </template>
            <template #cell(rentabilidad_actual)="data">
              {{ Number(data.item.rentabilidad_actual).toFixed(2) }} €
            </template>
            <template #cell(valor_actual)="data">
              {{ Number(data.item.valor_actual).toFixed(2) }} €
            </template>
            <template #cell(ingresos)="data">
              {{ Number(data.item.ingresos).toFixed(2) }} €
            </template>
            <template #cell(ganancias)="data">
              {{ Number(data.item.ganancias).toFixed(2) }} €
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios'
export default {
  data() {
    return {
      ahorrosFields: [
        {
          label: "ISIN",
          key: "etf.isin",
          thClass: 'text-uppercase text-sm',
          tdClass: 'text-truncate'
        },
        {
          label: "Posiciones",
          key: "etf.descripcion",
          thClass: 'text-uppercase text-sm',
        },
        {
          label: "Cantidad",
          key: "cantidad",
          thClass: 'text-uppercase text-sm',
          tdClass: 'text-center'
        },
        {
          label: "Desembolsos",
          key: "desembolsos",
          thClass: 'text-uppercase text-sm text-right',
          tdClass: 'text-right' 
        },
        {
          label: "P. Medio",
          key: "precio_medio",
          thClass: 'text-uppercase text-sm text-right',
          tdClass: 'text-right'
        },
        {
          label: "P. Actual",
          key: "precio_actual",
          thClass: 'text-uppercase text-sm text-right',
          tdClass: 'text-right'
        },
        {
          label: "Rent. Actual",
          key: "rentabilidad_actual",
          thClass: 'text-uppercase text-sm text-right',
          tdClass: 'text-right'
        },
        {
          label: "Valor Actual",
          key: "valor_actual",
          thClass: 'text-uppercase text-sm text-right',
          tdClass: 'text-right'
        },
        {
          label: "Ingresos",
          key: "ingresos",
          thClass: 'text-uppercase text-sm text-right',
          tdClass: 'text-right'
        },
        {
          label: "Ganancias",
          key: "ganancias",
          thClass: 'text-uppercase text-sm text-right',
          tdClass: 'text-right'
        }
      ],
      ahorrosItems: []
    }
  },
  created() {
    this.fetchAhorrosDeCliente(this.$route.params.id_cliente)
  },
  methods: {
    fetchAhorrosDeCliente(id_cliente) {
      axios.get(`/users/savings/${id_cliente}`)
      .then(res => {
        // console.log(res)
        this.ahorrosItems.push(res.data.data)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style>

</style>