<template>
  <div class="app" :class="{ autenticado: loggedIn }">
    <div
      class="app-sidebar-wrapper"
      :class="{ 'show-sidebar': activeSidebar && loggedIn }"
    >
      <div class="app-sidebar sidebar">
        <div>
          <div class="logo d-flex justify-content-between py-1 px-3">
            <div class="header-sidebar">
              <img src="@/assets/images/logo-admin.svg" alt="" class="h-9" />
            </div>
            <!-- <b-button
            class="toggle-menu d-lg-none"
            variant="outline-light"
            @click="activeSidebar = !activeSidebar"
          >
            <svg
              class="icon-sm"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              /></svg
          ></b-button> -->
          </div>
          <div class="menu overflow-auto">
            <hr class="mt-0 mb-3 bg-gray-400" />
            <!--<div class="d-flex align-items-start px-3">
            <div class="w-10 h-10 border rounded-circle"></div>
            <div class="text-white ml-3">
              <p class="mb-0">Nombre de usuario</p>
              <span class="text-sm text-gray-400 d-block -mt-0.5">Rol</span>
            </div>
          </div>
          <hr class="my-3 bg-gray-400" /> -->
            <b-nav vertical class="mt-10">
              <b-nav-item
                to="/usuarios"
                class="
                  flex
                  items-center
                  py-2
                  px-8
                  bg-gray-200
                  text-gray-700
                  border-r-4 border-gray-700
                "
                href="#"
              >
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="mx-4 font-medium">Usuarios</span>
              </b-nav-item>
              <b-nav-item
                to="/noticias"
                class="
                  flex
                  items-center
                  py-2
                  px-8
                  bg-gray-200
                  text-gray-700
                  border-r-4 border-gray-700
                "
                href="#"
              >
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                  />
                </svg>
                <span class="mx-4 font-medium">Noticias</span>
              </b-nav-item>
              <b-nav-item
                to="/etfs"
                class="
                  flex
                  items-center
                  py-2
                  px-8
                  bg-gray-200
                  text-gray-700
                  border-r-4 border-gray-700
                "
                href="#"
              >
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="mx-4 font-medium">ETFs</span>
              </b-nav-item>
              <b-nav-item
                to="/testimonios"
                class="
                  flex
                  items-center
                  py-2
                  px-8
                  bg-gray-200
                  text-gray-700
                  border-r-4 border-gray-700
                "
                href="#"
              >
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  />
                </svg>
                <span class="mx-4 font-medium">Testimonios</span>
              </b-nav-item>
              <b-nav-item
                to="/faqs"
                class="
                  flex
                  items-center
                  py-2
                  px-8
                  bg-gray-200
                  text-gray-700
                  border-r-4 border-gray-700
                "
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                </svg>
                <span class="mx-4 font-medium">FAQs</span>
              </b-nav-item>
            </b-nav>
          </div>
        </div>
        <div class="exit text-white d-flex justify">
          <b-button class="text-white" variant="danger" @click="logout" block
            >Salir</b-button
          >
        </div>
      </div>
    </div>

    <div
      class="app-main"
      :class="{ 'sidebar-is-open': activeSidebar && loggedIn }"
    >
      <div class="header-bar d-flex shadow-sm" v-if="loggedIn">
        <div class="d-flex">
          <!-- <div class="row h-100"> -->
          <div class="mr-2 align-self-center">
            <b-button
              class="toggle-menu border"
              variant="outline-dark"
              @click="activeSidebar = !activeSidebar"
            >
              <svg
                class="icon-sm"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                /></svg
            ></b-button>
          </div>
          <!-- <div class="col-1 align-self-center justify-end">Avatar</div> -->
          <!-- </div> -->
        </div>
      </div>
      <main class="app-content" :class="{ autenticado: loggedIn }">
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeSidebar: false,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
  },
  created() {
    // this.matchMediaQuery();
    // window.addEventListener("resize", this.onResize);
  },
  methods: {
    matchMediaQuery() {
      const mq = window.matchMedia("(min-width: 992px)");
      if (mq.matches) {
        this.activeSidebar = true;
      }
    },
    onResize() {
      if (window.innerWidth > 992) {
        this.activeSidebar = true;
      }
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/_mixins.scss";

.app {
  display: flex;
  // grid-template-columns: 250px auto;
  position: relative;
  overflow: hidden;

  &-sidebar-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    max-width: 250px;
    transform: translateX(-250px);
    z-index: 1030;
    transition: transform 0.5s ease;

    @include media-breakpoint-up(lg) {
      position: static;
      width: 0;
      min-width: 0;
      transition: min-width 0.5s ease;
    }
  }

  &-sidebar {
    height: 100vh;
    overflow: hidden;
    // width: 100%;
    // display: grid;
    // grid-template-columns: 250px;
    // grid-template-rows: 50px calc(100vh - 100px) 50px;
    background-color: #2d4960;
    // transform: translateX(-250px);
  }

  &-sidebar-wrapper.show-sidebar {
    transform: translate(0, 0);

    @include media-breakpoint-up(lg) {
      transform: translateX(0);
      min-width: 250px;
    }
  }

  &-main {
    background-color: #f8f8f8;
    width: 100%;
    transform: none;
    transition: transform 0.5s ease;
    flex-grow: 1;
  }

  &-main.sidebar-is-open {
    @include media-breakpoint-up(lg) {
      transform: translateX(0);
    }
  }

  // &-content {
  //   display: grid;
  //   grid-template-rows: 100vh;
  // }

  &-content.autenticado {
    display: block;
  }
}

.sidebar-is-open {
  transform: translateX(250px);
}

@include media-breakpoint-up(lg) {
  .app {
    &-sidebar-wrapper {
      position: static;
    }

    &-sidebar {
      // grid-template-columns: none;
      // position: static;
      // z-index: 0;
    }

    &.autenticado {
      // grid-template-columns: 250px auto;
    }
  }
}

.logo {
  color: #fff;
  text-align: center;
  align-self: center;
}

.logo-img {
  height: calc(50px - 0.5rem);
}

.header-bar {
  background-color: #fff;
  height: 50px;
}

.open-enter-active {
  transition: all 0.4s ease;
}

.open-leave-active {
  transition: all 0.4s ease;
}

.open-enter-to,
.open-leave {
  grid-template-columns: 250px auto;
}

.open-enter,
.open-leave-to {
  grid-template-columns: 0 auto;
}

.nav-link {
  color: var(--gray-400);
}

.nav-link.router-link-active {
  color: var(--white);
}

.toggle-menu {
  height: 50px;
  width: 50px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.justify {
  justify-content: space-around;
  padding-bottom: 5px;
}
</style>
